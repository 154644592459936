import { useCallback } from "react";
import { Config } from "../Config";
import { StoredStats } from "../Types/Stats";

const statsURI = "v1/governance/stats";

export const useStats = () => {
  const getStats = useCallback(async () => {
    const endpoint = `${Config.govApiBaseUrl}/${statsURI}`;
    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error(
        `Error fetching stats: ${response.statusText} (${response.status})`
      );
    }

    const stats: StoredStats = await response.json();

    return stats;
  }, []);
  return { getStats };
};
