import React from "react";
import { Body } from "Components/Body";
import { Header } from "Components/Header";
import { UnfiGovernance } from "Views/UnfiGovernance";
import { UnfiGovernanceHeader } from "Views/UnfiGovernanceHeader";
import { MyDashboardHeader } from "Views/MyDashboardHeader";
import { MyDashboard } from "Views/MyDashboard";
import { CreateReferendum } from "Views/CreateReferendum";
import { Referendum } from "Views/Referendum";
import { HomeHeader } from "Views/HomeHeader";
import { Home } from "Views/Home";

export interface AppRoute {
  path: string;
  title: string;
  hideBreadcrumbs: boolean;
  render: () => JSX.Element;
}

const routes: AppRoute[] = [];

const routeDefs: Pick<AppRoute, "hideBreadcrumbs"> = {
  hideBreadcrumbs: false,
};

routes.push({
  ...routeDefs,
  path: "/my-dashboard",
  title: "Dashboard",
  render: () => (
    <>
      <Header>
        <MyDashboardHeader />
      </Header>
      <Body>
        <MyDashboard />
      </Body>
    </>
  ),
});

routes.push({
  ...routeDefs,
  title: "Create referendum",
  path: "/governance/referendums/create",
  render: () => (
    <>
      <Header />
      <Body>
        <CreateReferendum />
      </Body>
    </>
  ),
});

routes.push({
  ...routeDefs,
  title: "View referendum",
  path: "/governance/referendums/:id",
  render: () => (
    <>
      <Header />
      <Body>
        <Referendum />
      </Body>
    </>
  ),
});

routes.push({
  ...routeDefs,
  title: "Governance",
  path: "/governance",
  render: () => (
    <>
      <Header>
        <UnfiGovernanceHeader />
      </Header>
      <Body>
        <UnfiGovernance />
      </Body>
    </>
  ),
});

routes.push({
  ...routeDefs,
  hideBreadcrumbs: true,
  title: "Home",
  path: "/",
  render: () => (
    <>
      <Header>
        <HomeHeader />
      </Header>
      <Body>
        <Home />
      </Body>
    </>
  ),
});

export const appRoutes = routes;
