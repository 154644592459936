import styled from "styled-components";

export const Back = styled.span`
  margin: 0.5rem 0;

  svg {
    cursor: pointer;
    height: auto;
    width: 2rem;
    fill: ${(props) => props.theme.primaryColor};
    transform: rotate(180deg);
  }
`;
