import { blockchainConfigMap, Blockchains } from "@unifiprotocol/core-sdk";
import React, { useMemo } from "react";
import {
  BlockVotingPower,
  DetailedVotingPower,
} from "../../../Types/Referendum";
import {
  DetailedVotingPowerTableWrapper,
  RoundedBlockchainLogo,
  TableHeader,
} from "./DetailedVotingPowerTableStyles";

export const DetailedVotingPowerTable = ({
  detailedVotingPower,
}: {
  detailedVotingPower: DetailedVotingPower;
}) => {
  const blockchainsData = useMemo(() => {
    if (!detailedVotingPower.blockchains) {
      return [];
    }
    return (
      Object.values(detailedVotingPower.blockchains) as BlockVotingPower[]
    ).map(({ blockHeight, blockchain: _blockchain, votingPower }) => {
      const blockchain = _blockchain as Blockchains;
      return {
        votingPower,
        blockHeight,
        logo: blockchainConfigMap[blockchain]!.logoURI,
      };
    });
  }, [detailedVotingPower.blockchains]);

  if (!detailedVotingPower.blockchains) {
    return <></>;
  }
  return (
    <DetailedVotingPowerTableWrapper>
      <>
        <TableHeader>Blockchain</TableHeader>
        <TableHeader>Block Height</TableHeader>
        <TableHeader>Voting power</TableHeader>
      </>
      {blockchainsData.map(({ logo, blockHeight, votingPower }) => (
        <>
          <div>
            <RoundedBlockchainLogo src={logo} />
          </div>
          <div>{blockHeight}</div>
          <div>{votingPower}</div>
        </>
      ))}
    </DetailedVotingPowerTableWrapper>
  );
};
