import styled from "styled-components";
import { CoreTag } from "../../Components/Referendums/Styles";
import { InputStyle } from "../../Styles";
import { ProposalWidget } from "../CreateReferendum/Styles";

export const ProposalWrapper = styled.div`
  background: #fff;
  padding: 0 1rem;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;

  .Button {
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ProposalLeft = styled.div`
  padding: 1rem 0.5rem;
  width: 67%;

  h1 {
    line-height: 2rem;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ProposalRight = styled.div`
  margin: 1rem 0;
  width: 31%;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ProposalVoteWrapper = styled.div`
  margin: 0.75rem 0;
`;

export const ProposalVote = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColoredLink = styled.a`
  color: #000 !important;
  height: fit-content;
  display: flex;
`;

export const FlexSubtitle = styled.h2`
  display: flex;
  * {
    margin-left: 0.1rem;
  }
`;

export const BlockHeightListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    display: block;
  }
`;

export const BlockHeightListItem = styled.span`
  height: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  flex: 1 1 calc(50% - 0.4rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: rgb(84, 84, 84);
  gap: 0.3rem;
  background: rgba(239, 239, 239, 0.3);
  font-size: 1rem;
  img {
    height: 60%;
    border-radius: 100%;
  }
`;

export const RoundedBlockchainIcon = styled.img`
  height: 80%;
  border-radius: 100%;
`;

interface IProposalVoteBar {
  readonly votes: number;
}

export const ProposalVoteBar = styled.div<IProposalVoteBar>`
  margin: 0.75rem 0;
  height: 6px;
  border-radius: 6px;
  background: #eee;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    border-radius: 6px;
    background: ${(props) => props.theme.primaryColor};
    width: ${(props) => (props.votes ? `${props.votes}%` : 0)};
  }
`;

export const VoteChoice = styled(InputStyle)<{ selected: boolean }>`
  cursor: pointer;
  margin: 0.3rem 0;
  border-color: ${(props) =>
    props.selected ? props.theme.primaryColor : "rgba(0, 0, 0, 0.2)"};
  font-weight: ${(props) => (props.selected ? 600 : "unset")};

  &:hover {
    border-color: ${(props) =>
      props.selected ? props.theme.primaryColor : "rgba(0, 0, 0, 0.4)"};
  }
`;

export const ProposalBodyWrapper = styled.div`
  margin: 2rem 0;
  text-align: justify;
  font-size: 0.95rem;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ProposalBadgesWrapper = styled.div`
  margin: 1rem 0;
  display: flex;

  > span {
    margin-right: 1rem;
  }
`;

export const OnGoingBadge = styled(CoreTag)`
  width: 5rem;
  color: #000;
  font-weight: 600;
  background: #3df997;
  justify-content: center;

  > span {
    color: #000;
  }
`;

export const ClosedBadge = styled(OnGoingBadge)`
  background: none;
  border: 1px solid #8f8f8f;
`;

export const VotesTableWrapper = styled(ProposalWidget)`
  padding: 0;

  .VoteTableHeader {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1.3rem;
    font-weight: 600;
  }

  .VoteTableBody {
    overflow-y: scroll;
    max-height: 25rem;
  }
`;

export const VotesTableRow = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    > span {
      width: 100% !important;
    }
  }

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .voter-address {
    width: 50%;
  }
  .voter-option {
    width: 30%;
  }
  .voter-power {
    display: flex;
    align-items: center;
    font-weight: 600;

    > img {
      margin-left: 0.25rem;
      height: 1.2rem;
      width: auto;
    }
  }
`;
