import React, { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ReactMarkdown from "react-markdown";
import DatePicker from "react-datepicker";
import { Close, TrendingFlat } from "@material-ui/icons";
import { Adapter } from "../../Store/Adapter";
import { Button } from "../../Components/Button";
import {
  CreateProposalWrapper,
  TitleField,
  BodyField,
  CreateProposalLeft,
  CreateProposalRight,
  ProposalWidget,
  DatePickerWrapper,
  Choice,
  ChoiceText,
  AddChoiceButton,
  CreateProposalLeftHeader,
  ToggleViewMode,
  MarkdownLink,
  CheckboxWrapper,
  ErrorMessage,
} from "./Styles";
import {
  Choice as TChoice,
  CreateReferendumDto,
  ReferendumSchema,
} from "../../Types/Referendum";
import { useHistory } from "react-router";
import { Back } from "../../Components/Back";
import { useReferendums } from "../../Hooks/useReferendum";
import { BN } from "../../Utils/BigNumber";
import { useNotifications } from "../../Hooks/useNotifications";
import { Loading } from "../../Store/Loading";
import { NotificationType } from "../../Components/Notifications";
import { ValidationError } from "yup";
import { ProposalBodyWrapper } from "../Referendum/Styles";

import "react-datepicker/dist/react-datepicker.css";
import { ApiError } from "../../Types/Error";
import { Checkbox } from "@material-ui/core";

const defaultChoices: TChoice[] = [
  { index: 0, choice: "Approve" },
  { index: 1, choice: "Decline" },
];

const WEEK_IN_MS = 24 * 3600 * 7 * 1000;
const MONTH_IN_MS = 24 * 3600 * 31 * 1000;

export const CreateReferendum: React.FC = () => {
  const history = useHistory();
  const adapter = useRecoilValue(Adapter);
  const [, setLoading] = useRecoilState(Loading);
  const { sendNotification } = useNotifications();
  const { createReferendum } = useReferendums();
  const [choices, setChoices] = useState<TChoice[]>(defaultChoices);
  const [editMode, setEditMode] = useState(true);
  // const [currentBlockHeight, setCurrentBlockHeight] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startNow, setStartNow] = useState<boolean>(true);
  const [endDate, setEndDate] = useState<Date>(() => {
    const currDate = new Date();
    currDate.setDate(currDate.getDate() + 10);
    return currDate;
  });

  const isInvalidLength = useMemo(() => {
    if (!endDate || !startDate) return;
    const timeDiff = endDate.valueOf() - startDate.valueOf();
    return timeDiff < WEEK_IN_MS || timeDiff > MONTH_IN_MS;
  }, [endDate, startDate]);

  const onChoiceChange = useCallback((choice: TChoice, newValue: string) => {
    setChoices((ch) =>
      ch.map((c) => {
        if (c.index === choice.index) {
          c.choice = newValue;
        }
        return c;
      })
    );
  }, []);

  const onDeleteChoice = useCallback((choice: TChoice) => {
    setChoices((ch) => ch.filter((c) => c.index !== choice.index));
  }, []);

  const onSubmit = useCallback(async () => {
    if (!adapter || !adapter.isConnected()) {
      return sendNotification({
        notification: "NO_WALLET",
        type: "info",
      });
    }

    const actualStartDate = startNow ? new Date() : startDate;

    try {
      // Validation
      await ReferendumSchema.validate({
        startDate: actualStartDate,
        endDate,
        title,
        body,
        choices: choices.map((c) => c.choice),
      });

      setLoading((l) => ({ ...l, loading: l.loading + 1 }));
      sendNotification({
        notification: "LOADING",
        type: "info",
      });

      const payload = getReferendumPayload(
        adapter.getAddress(),
        title,
        body,
        actualStartDate,
        endDate,
        choices
      );

      await createReferendum(payload).then(({ success, id, error }) => {
        if (success) {
          sendNotification({
            notification: "PROPOSAL_CREATED",
            type: "success",
          });
          history.push(`/governance/referendums/${id}`);
        } else {
          sendNotification({
            notification:
              error instanceof ApiError
                ? (error.errCode as any)
                : "PROPOSAL_ERROR",
            type: "error",
          });
        }
        setLoading((l) => ({ ...l, totalRequests: l.totalRequests + 1 }));
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errors = err.errors as NotificationType[];
        errors.forEach((e: NotificationType) => {
          sendNotification({
            notification: e,
            type: "error",
          });
        });
      }
    }
  }, [
    startNow,
    adapter,
    sendNotification,
    startDate,
    endDate,
    title,
    body,
    choices,
    setLoading,
    createReferendum,
    history,
  ]);

  return (
    <CreateProposalWrapper>
      <CreateProposalLeft>
        <CreateProposalLeftHeader>
          <Back>
            <TrendingFlat onClick={() => history.push("/governance")} />
          </Back>
          <ToggleViewMode onClick={() => setEditMode((s) => !s)}>
            {editMode ? "Preview" : "Edit"}
          </ToggleViewMode>
        </CreateProposalLeftHeader>
        {editMode ? (
          <>
            <TitleField
              onChange={(e) => setTitle(e.currentTarget.value)}
              value={title}
            />
            <BodyField
              onChange={(e) => setBody(e.currentTarget.value)}
              value={body}
            />
            <div>
              <MarkdownLink
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                * Markdown is allowed
              </MarkdownLink>
            </div>
          </>
        ) : (
          <>
            <h1>{title || "Proposal title"}</h1>
            <br />
            <ProposalBodyWrapper>
              <ReactMarkdown>{body || "What is your proposal?"}</ReactMarkdown>
            </ProposalBodyWrapper>
          </>
        )}
      </CreateProposalLeft>
      <CreateProposalRight>
        <ProposalWidget>
          <h1>Dates</h1>
          <h2>Start date</h2>
          <DatePickerWrapper>
            {isInvalidLength && (
              <ErrorMessage>
                Referendums length should be between a week and a month (31
                days) long.
              </ErrorMessage>
            )}
            <CheckboxWrapper>
              Start on creation
              <Checkbox
                color="primary"
                defaultChecked={true}
                value={startNow}
                onChange={() => setStartNow(!startNow)}
              />
            </CheckboxWrapper>
            <DatePicker
              showTimeSelect
              disabled={startNow}
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={startNow ? new Date() : startDate}
              onChange={(dt: Date) => setStartDate(dt)}
            />
          </DatePickerWrapper>
          <h2>End date</h2>
          <DatePickerWrapper>
            <DatePicker
              showTimeSelect
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={endDate}
              onChange={(dt: Date) => setEndDate(dt)}
            />
          </DatePickerWrapper>
          {/* <h2>Block height</h2>
          <ProposalInput
            value={currentBlockHeight}
            onChange={(e) => setCurrentBlockHeight(e.currentTarget.value)}
          /> */}
        </ProposalWidget>
        <ProposalWidget>
          <h1>Choices</h1>
          {choices.map((c, i) => (
            <Choice key={c.index}>
              <span>{i + 1}</span>
              <ChoiceText
                value={c.choice}
                onChange={(e) => onChoiceChange(c, e.currentTarget.value)}
              />
              <Close onClick={() => onDeleteChoice(c)} />
            </Choice>
          ))}
          <AddChoiceButton
            onClick={() =>
              setChoices((s) => [
                ...s,
                {
                  index: s[s.length - 1] ? s[s.length - 1].index + 1 : 0,
                  choice: "",
                },
              ])
            }
          >
            Add choice
          </AddChoiceButton>
        </ProposalWidget>
        <Button disabled={isInvalidLength} onClick={onSubmit}>
          Submit
        </Button>
      </CreateProposalRight>
    </CreateProposalWrapper>
  );
};

function getReferendumPayload(
  creator: string,
  title: string,
  body: string,
  startDate: Date,
  endDate: Date,
  choices: TChoice[]
): CreateReferendumDto {
  return {
    name: title,
    body,
    choices: choices.map((c) => c.choice),
    creator,
    startDate: BN(startDate.valueOf()).dp(0).toNumber(),
    endDate: BN(endDate.valueOf()).dp(0).toNumber(),
  };
}
