import {
  blockchainConfigMap,
  Blockchains,
  WalletConnectors,
} from "@unifiprotocol/core-sdk";
import React, { useMemo } from "react";
import { getWalletIcon } from "../../Utils/Icon";

import "./index.scss";

export const WalletSelector = ({
  blockchain,
  onSelect,
}: {
  blockchain: Blockchains;
  onSelect: (b: WalletConnectors) => any;
}) => {
  const availableWallets = useMemo(
    () => blockchainConfigMap[blockchain]!.wallets,
    [blockchain]
  );
  const icons = useMemo(
    () =>
      availableWallets.map(({ name }) =>
        getWalletIcon(blockchain, name as WalletConnectors)
      ),
    [availableWallets, blockchain]
  );
  return (
    <ul className="WalletSelector">
      {availableWallets.map((wallet, i) => (
        <li
          key={wallet.name}
          className="WalletSelector_option"
          onClick={() => onSelect(wallet.name as WalletConnectors)}
        >
          {wallet.displayName}
          <img
            className="WalletSelector_option_logo"
            src={icons[i]}
            alt={wallet.name}
          ></img>
        </li>
      ))}
    </ul>
  );
};
