import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Updater } from "./Components/Updater";
import Loading from "./Components/Loading";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "styled-components";
import { Theme } from "./Styles/Theme";
import { appRoutes } from "./Router";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <RecoilRoot>
          <ToastProvider autoDismissTimeout={3000} autoDismiss={true}>
            <Loading />
            <Router>
              <Switch>
                {appRoutes.map(({ path, render }, key) => (
                  <Route key={key} path={path}>
                    {render()}
                  </Route>
                ))}
              </Switch>
            </Router>
            <Updater />
          </ToastProvider>
        </RecoilRoot>
      </ThemeProvider>
    </div>
  );
}

export default App;
