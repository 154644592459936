import styled from "styled-components";

export const CreateProposalWrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;

  .Button {
    width: 100%;
  }
`;

export const CreateProposalLeft = styled.div`
  width: 67%;
`;

export const CreateProposalRight = styled.div`
  margin: 1rem 0;
  width: 31%;
`;

export const CreateProposalLeftHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleViewMode = styled.span`
  padding: 0.75rem;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const TitleField = styled.input.attrs({
  type: "text",
  placeholder: "Proposal title ✏️ ",
})`
  display: block;
  width: 100%;
  border: 0;
  font-size: 2rem;
  padding: 1rem;
  margin: 1rem 0;
  outline: 0;
  border-radius: 5px;
  border: 2px solid #d1fee7;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.primaryColor};
  }

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const BodyField = styled.textarea.attrs({
  type: "text",
  placeholder: "What is your proposal? ✏️ ",
})`
  display: block;
  width: 100%;
  min-height: 30rem;
  font-family: inherit;
  resize: none;
  border: 0;
  padding: 1rem;
  font-size: 1rem;
  outline: 0;
  border-radius: 5px;
  border: 2px solid #d1fee7;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.primaryColor};
  }

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const ProposalWidget = styled.div`
  padding: 1.5rem;
  border: 1px solid #e8e8e8;
  border-top: 5px solid ${(props) => props.theme.primaryColor};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  h1 {
    margin-bottom: 0.75rem;
  }

  h2 {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    color: #8f8f8f;
  }
`;

const inputStylesRaw = `
text-align: center;
font-size: 1rem;
padding: 0.75rem;
border-radius: 4px;
border: 1px solid rgba(0, 0, 0, 0.2);
outline: 0;
transition: border-color 0.3s;

&:hover {
  border-color: rgba(0, 0, 0, 0.4);
}


`;

export const inputStyles = styled.input`
  ${inputStylesRaw}

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const CheckboxWrapper = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const ProposalInput = styled(inputStyles).attrs({
  placeholder: "Enter snapshot block height",
  type: "text",
})``;

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }

  input {
    width: 100%;
    ${inputStylesRaw}

    &:focus {
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const Choice = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const ChoiceText = styled.input`
  width: 95%;
  text-align: center;
  font-size: 1rem;
  border: 0;
  outline: 0;
`;

export const AddChoiceButton = styled.button`
  width: 100%;
  background: #fff;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  cursor: pointer;
  transition: border-color 0.3s;

  &:focus {
    outline: 0;
  }

  &:hover {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const MarkdownLink = styled.a`
  text-decoration: none;
  font-style: italic;
  color: #292929;
  transition: color 0.1s;

  &:hover {
    color: #000;
  }
`;
