import { Blockchains } from "@unifiprotocol/core-sdk";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Config } from "../Config";
import { Adapter } from "../Store/Adapter";

export const useContracts = () => {
  const adapter = useRecoilValue(Adapter);

  const blockchain = useMemo(
    () => adapter?.blockchainConfig.blockchain || Blockchains.Ethereum,
    [adapter]
  );

  const contracts = useMemo(() => {
    const newContracts = blockchain && Config.contracts[blockchain];
    if (!blockchain) return {};
    return newContracts;
  }, [blockchain]);

  return contracts;
};
