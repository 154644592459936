import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Gesture, Memory } from "@material-ui/icons";
import { useGovernance } from "../../Hooks/useGovernance";
import { ReferendumDetail, VoteReferendumDto } from "../../Types/Referendum";
import { BN, localiseNumber } from "../../Utils/BigNumber";
import {
  ProposalInput,
  DatePickerWrapper,
  ProposalWidget,
} from "../CreateReferendum/Styles";
import {
  BlockHeightListItem,
  BlockHeightListWrapper,
  ClosedBadge,
  ColoredLink,
  FlexSubtitle,
  OnGoingBadge,
  ProposalBadgesWrapper,
  ProposalBodyWrapper,
  ProposalLeft,
  ProposalRight,
  ProposalVote,
  ProposalVoteBar,
  ProposalVoteWrapper,
  ProposalWrapper,
  VoteChoice,
} from "./Styles";
import { shortAddress, getEtherscanAddressHref } from "../../Utils/ETH";
import { TrendingFlat } from "@material-ui/icons";
import { Back } from "../../Components/Back";
import { useRecoilState, useRecoilValue } from "recoil";
import { Loading } from "../../Store/Loading";
import { Button } from "../../Components/Button";
import { useReferendums } from "../../Hooks/useReferendum";
import { useNotifications } from "../../Hooks/useNotifications";
import { Adapter } from "../../Store/Adapter";
import { CurrentPageTitle } from "../../Store/Navigation";
import { VoteConfirmation } from "./Components/VoteConfirmation";
import { VotesTable } from "./Components/VotesTable";
import { CoreTag } from "../../Components/Referendums/Styles";
import { UiWrappedLoading } from "../../Components/UI/Loading";
import { ApiError } from "../../Types/Error";
import { blockchainConfigMap, Blockchains } from "@unifiprotocol/core-sdk";
import { WarningAlert } from "../../Components/Alert";
import { mutateUnfiToUnifi } from "../../Utils/Governance";

export const Referendum = () => {
  const adapter = useRecoilValue(Adapter);
  const history = useHistory();
  const [, setLoading] = useRecoilState(Loading);
  const { id } = useParams<{ id: string }>();
  const [referendum, setReferendum] = useState<ReferendumDetail>();
  const { getReferendum } = useGovernance();
  const { sendNotification } = useNotifications();
  const [selectedVote, setSelectedVote] = useState<string | undefined>();
  const { voteReferendum } = useReferendums();
  const [showVoteConfirmation, setShowVoteConfirmation] = useState(false);
  const [, setCurrentPageTitle] = useRecoilState(CurrentPageTitle);

  const hasStarted = useMemo(
    () => referendum && Date.now() > referendum.startDate,
    [referendum]
  );

  const fetchProposal = useCallback(() => {
    return getReferendum(id).then((referendum) => {
      setReferendum(referendum);
      setCurrentPageTitle(referendum!.name);
    });
  }, [getReferendum, id, setCurrentPageTitle]);

  useEffect(() => {
    setLoading((l) => ({ ...l, loading: l.loading + 1 }));
    fetchProposal().then((referendum) => {
      setLoading((l) => ({ ...l, totalRequests: l.totalRequests + 1 }));
    });
    // eslint-disable-next-line
  }, []);

  const totalVotingPower = useMemo(() => {
    if (!referendum) return 0;
    return Object.keys(referendum.reducedVotingPower).reduce(
      (acc: number, i) => {
        const item = referendum.reducedVotingPower[i] ?? "0";
        return BN(acc).plus(item).toNumber();
      },
      0
    );
  }, [referendum]);

  const totalVotes = useMemo(() => {
    if (!referendum) return 0;
    return Object.keys(referendum.reducedVotes).reduce((acc: number, i) => {
      const item = referendum.reducedVotes[i] ?? "0";
      return BN(acc).plus(item).toNumber();
    }, 0);
  }, [referendum]);

  const normalizedVotes = useMemo(() => {
    if (!referendum) return [];
    return referendum.choices.map((choice, i) => {
      return {
        choice,
        votes: referendum.reducedVotes[i + 1] ?? 0,
        votingPower: referendum.reducedVotingPower[i + 1] ?? 0,
      };
    });
  }, [referendum]);

  const onSubmitVote = useCallback(async () => {
    if (!adapter || !adapter.isConnected()) {
      return sendNotification({
        notification: "NO_WALLET",
        type: "info",
      });
    }
    if (!selectedVote || !referendum) return;
    const selectedChoice: number = referendum.choices.reduce(
      (t: number, choice, idx) => {
        if (choice === selectedVote) {
          return idx + 1;
        }
        return t;
      },
      0
    );
    if (selectedChoice === 0) return; // Choice not found!
    setLoading((l) => ({ ...l, loading: l.loading + 1 }));

    const payload: VoteReferendumDto = {
      referendumId: id,
      voter: adapter.getAddress(),
      choice: selectedChoice,
    };

    return voteReferendum(payload).then(({ success, error }) => {
      if (success) {
        sendNotification({
          notification: "VOTE_SUBMITED",
          type: "success",
        });
        fetchProposal();
      } else {
        sendNotification({
          notification:
            error instanceof ApiError ? (error.errCode as any) : "VOTE_ERROR",
          type: "error",
        });
      }
      setLoading((l) => ({ ...l, totalRequests: l.totalRequests + 1 }));
    });
  }, [
    selectedVote,
    referendum,
    adapter,
    id,
    setLoading,
    voteReferendum,
    sendNotification,
    fetchProposal,
  ]);

  const body = useMemo(() => {
    if (!referendum) return undefined;
    // Replace IPFS links by ProxyIPFS
    return referendum.body.replace(
      /ipfs:\/\/(Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,})/g,
      (x, y) => `https://proxy.unifiprotocol.com/ipfs/${y}`
    );
  }, [referendum]);

  if (!referendum) {
    return <UiWrappedLoading />;
  }

  return (
    <ProposalWrapper>
      <ProposalLeft>
        <Back>
          <TrendingFlat onClick={() => history.push("/governance")} />
        </Back>
        <h1>{mutateUnfiToUnifi(referendum.name)}</h1>
        <ProposalBadgesWrapper>
          {!referendum.isClosed ? (
            <OnGoingBadge>
              <span>Ongoing</span>
            </OnGoingBadge>
          ) : (
            <ClosedBadge>
              <span>Closed</span>
            </ClosedBadge>
          )}
          {referendum.isCore && (
            <CoreTag>
              <Memory />
              <span>Core</span>
            </CoreTag>
          )}
        </ProposalBadgesWrapper>
        <ProposalBodyWrapper>
          <ReactMarkdown>{body ?? ""}</ReactMarkdown>
        </ProposalBodyWrapper>
        <VotesTable referendum={referendum} />
      </ProposalLeft>
      <ProposalRight>
        <ProposalWidget>
          <h1>Information</h1>
          <FlexSubtitle>
            Author
            <ColoredLink
              target="_blank"
              href={`https://signator.io/view?ipfs=${referendum.ipfs}`}
            >
              <Gesture></Gesture>
            </ColoredLink>
          </FlexSubtitle>
          <ProposalInput
            disabled
            value={shortAddress(referendum.creator)}
            onClick={() =>
              window.open(getEtherscanAddressHref(referendum.creator))
            }
          />
          <h2>Start date</h2>
          <DatePickerWrapper>
            <DatePicker
              disabled
              showTimeSelect
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={new Date(referendum.startDate)}
              onChange={() => {}}
            />
          </DatePickerWrapper>
          <h2>End date</h2>
          <DatePickerWrapper>
            <DatePicker
              disabled
              showTimeSelect
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={new Date(referendum.endDate)}
              onChange={() => {}}
            />
          </DatePickerWrapper>

          <h2>Block height</h2>
          {!hasStarted && <WarningAlert>Available upon start</WarningAlert>}
          {hasStarted && (
            <BlockHeightListWrapper>
              {Object.entries(referendum.blockHeights).map(
                ([blockchain, blockHeight], idx) => (
                  <BlockHeightListItem key={idx}>
                    <img
                      alt={blockchain}
                      src={
                        blockchainConfigMap[blockchain as Blockchains]!.logoURI
                      }
                    />
                    <span>{blockHeight}</span>
                  </BlockHeightListItem>
                )
              )}
            </BlockHeightListWrapper>
          )}
        </ProposalWidget>
        {!referendum.isClosed && (
          <ProposalWidget>
            <h1>Cast your vote</h1>
            {referendum.choices.map((c, i) => {
              return (
                <VoteChoice
                  selected={selectedVote === c}
                  key={`voteChoice-${i}`}
                  onClick={() => setSelectedVote(c)}
                >
                  {c}
                </VoteChoice>
              );
            })}
            <Button
              disabled={!selectedVote}
              onClick={() => setShowVoteConfirmation(true)}
            >
              Vote
            </Button>
          </ProposalWidget>
        )}
        <ProposalWidget>
          <h1>Results</h1>
          <h2>Voting power</h2>
          {normalizedVotes.map(({ choice, votingPower }, i) => (
            <ProposalVoteWrapper key={i}>
              <ProposalVote>
                <span>
                  {choice} - {localiseNumber(BN(votingPower).dp(2).toFixed())}{" "}
                  UNIFI
                </span>
                <span>
                  {BN(votingPower)
                    .dividedBy(totalVotingPower)
                    .multipliedBy(100)
                    .dp(2)
                    .toNumber() || 0}
                  %
                </span>
              </ProposalVote>
              <ProposalVoteBar
                votes={
                  BN(votingPower)
                    .dividedBy(totalVotingPower)
                    .multipliedBy(100)
                    .dp(2)
                    .toNumber() || 0
                }
              />
            </ProposalVoteWrapper>
          ))}
          <h2>Votes</h2>
          {normalizedVotes.map(({ choice, votes }, i) => (
            <ProposalVoteWrapper key={i}>
              <ProposalVote>
                <span>
                  {choice} - {localiseNumber(votes)} votes
                </span>
                <span>
                  {BN(votes)
                    .dividedBy(totalVotes)
                    .multipliedBy(100)
                    .dp(1)
                    .toNumber() || 0}
                  %
                </span>
              </ProposalVote>
              <ProposalVoteBar
                votes={
                  BN(votes)
                    .dividedBy(totalVotes)
                    .multipliedBy(100)
                    .dp(1)
                    .toNumber() || 0
                }
              />
            </ProposalVoteWrapper>
          ))}
        </ProposalWidget>
      </ProposalRight>
      <VoteConfirmation
        isOpen={showVoteConfirmation}
        selectedOption={selectedVote}
        referendum={referendum}
        onClose={() => setShowVoteConfirmation(false)}
        onConfirm={onSubmitVote}
      />
    </ProposalWrapper>
  );
};
