import { useQuery } from "Hooks/useQuery";
import { useReferendums } from "Hooks/useReferendum";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoading, Loading } from "Store/Loading";
import {
  Referendum,
  referendumCategories,
  ReferendumCategoryKey,
} from "Types/Referendum";
import { Referendums } from "../../Components/Referendums";
import Pagination from "@material-ui/lab/Pagination";
import paginate, { Paginated } from "paginate-array";

import "./UnfiGovernance.scss";
import { goTop } from "Utils/Scroll";
import { UiWrappedLoading } from "Components/UI/Loading";

const ITEMS_PER_PAGE = 8;

export const UnfiGovernance = () => {
  const query = useQuery();
  const history = useHistory();
  const { getReferendums } = useReferendums();

  const initialTab =
    (query.get("c") as ReferendumCategoryKey) || ReferendumCategoryKey.All;
  const initialPage = Number(query.get("p"));

  const [proposals, setProposals] = useState<Referendum[]>([]);
  const [pagination, setPagination] = useState<Paginated<Referendum>>(
    paginate([])
  );
  const [category, setCategory] = useState<ReferendumCategoryKey>(initialTab);
  const [page, setPage] = useState<number>(initialPage || 1);

  const [, setLoading] = useRecoilState(Loading);
  const appIsLoading = useRecoilValue(isLoading);

  const handlePageChange = useCallback(
    (event: any, page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleCategoryChange = useCallback(
    (selectedCategory: ReferendumCategoryKey) => {
      setPage(1);
      setCategory(selectedCategory);
    },
    [setPage, setCategory]
  );

  const loadProposals = useCallback(() => {
    setLoading((l) => ({ ...l, loading: l.loading + 1 }));
    setProposals([]);
    getReferendums(category).then((p) => {
      setLoading((l) => ({ ...l, totalRequests: l.totalRequests + 1 }));
      setProposals(p);
    });
  }, [category, getReferendums, setLoading]);

  const paginateData = useCallback(() => {
    setPagination(paginate<Referendum>(proposals, page, ITEMS_PER_PAGE));
  }, [proposals, page]);

  const refreshUrlWithFiltersAndGoTop = useCallback(() => {
    goTop();
    history.push(`/governance?c=${category}&p=${page}`);
  }, [category, history, page]);

  useEffect(loadProposals, [loadProposals]);
  useEffect(paginateData, [paginateData]);
  useEffect(refreshUrlWithFiltersAndGoTop, [refreshUrlWithFiltersAndGoTop]);

  const drawPagination = () => {
    if (pagination.totalPages > 0) {
      return (
        <div className="pagination-wrapper">
          <Pagination
            page={page}
            count={pagination.totalPages}
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      );
    }
  };

  if(appIsLoading){
    return <UiWrappedLoading/>
  }
  return (
    <div className="UnfiGovernance">
      <div className="UnfiGovernance__content">
        <div className="UnfiGovernance__content__tabs">
          {referendumCategories.map(({ key, name }) => (
            <div
              key={key}
              onClick={() => {
                handleCategoryChange(key);
              }}
              className={`UnfiGovernance__content__tabs__tab ${
                key === category ? "active" : "no-active"
              }`}
            >
              {name}
            </div>
          ))}
        </div>
        <div className={`UnfiGovernance__content__body`}>
          <Referendums proposals={pagination.data}></Referendums>
        </div>
      </div>
      {drawPagination()}
    </div>
  );
};
