import {
  Blockchains,
  ERC20ABI,
  OfflineConnectors,
} from "@unifiprotocol/core-sdk";
import LVL3 from "./Adapter/ABI/LVL3";
import StakeABI from "./Adapter/ABI/STAKE";
import NEW_STAKE from "./Adapter/ABI/NEW_STAKE";

interface ContractInfo {
  address: string;
  decimals: number;
  abi: any;
}

const isDev = process.env.REACT_APP_ENV === "dev";
let govApiBaseUrl = "https://api.unifiprotocol.com";
if (isDev && process.env.REACT_APP_GOV_URL) {
  govApiBaseUrl = process.env.REACT_APP_GOV_URL;
}

export const Config = {
  blockchain: "ethereum",
  blockchainToken: "ETH",
  defaultOfflineProvider: OfflineConnectors.Cloudflare,
  transactionExplorer: "https://etherscan.io/tx/{{TRANSACTION_HASH}}",
  accountExplorer: "https://etherscan.io/address/{{ADDRESS}}",
  contracts: {
    [Blockchains.Ethereum]: {
      LVL3: {
        address: "0x2E2FB3Db9eCDB9B7d9eb05E00964c8941F7171a7",
        decimals: 18,
        abi: LVL3.abi,
      },
      UNFI: {
        address: "0x441761326490cACF7aF299725B6292597EE822c2",
        decimals: 18,
        abi: ERC20ABI,
      },
      STAKE_UNFI: {
        address: "0x97af9DFD6C473944878B27091Aa812347D054364",
        decimals: 18,
        abi: StakeABI,
      },
      VOTING_POWER: {
        // address: "0x658a7018314cC72cA1748E6070cCA3FeE030F224",
        address: "0x6b5e07063B3cd19BEF0d9e9F66d22dB5D517f52a",
        decimals: 18,
        abi: ERC20ABI,
      },
      OLD_VOTING_POWER: {
        address: "0xefC6De69C05E1F4D0A1BEdFaE8ff2F14b810c135",
        decimals: 18,
        abi: ERC20ABI,
      },
      VUNIFI: {
        address: "0x6b5e07063B3cd19BEF0d9e9F66d22dB5D517f52a",
        decimals: 18,
        abi: ERC20ABI,
      },
      NEW_STAKE_UNFI: {
        address: "0x90817bCcafAb6D5be9E0198252Ba70C542a91bac",
        decimals: 18,
        abi: NEW_STAKE,
      },
    },
    [Blockchains.Binance]: {
      UNFI: {
        address: "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.Avalanche]: {
      UNFI: {
        address: "0x42A99bB49b54811A95A36981Cb03d230A0Aef67B",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.FTM]: {
      UNFI: {
        address: "0x3824D0C574641CC8cb9253e6a84fE26E1E7a349F",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.Harmony]: {
      UNFI: {
        address: "0xEe7207c782d6937BE63E38FCF902fF59E5498386",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.Iotex]: {
      UNFI: {
        address: "0xD2ECEDa377DAe9dAF952c18786be736bEC9312cC",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.Ontology]: {
      UNFI: {
        address: "0xb510ac30c04c86Fc2FcFCc2012A415d2cEd8e629",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
    [Blockchains.Polygon]: {
      UNFI: {
        address: "0xed6072Ef5032484c2CC5f76630310e4BD36e62d2",
        decimals: 18,
        abi: ERC20ABI,
      },
    },
  } as { [key in Blockchains]?: Record<string, ContractInfo> },
  globalPrecision: Math.pow(10, 18),
  globalPowerPrecision: 18,
  percentagePrecision: 100000,
  numberLocale: "en-US",
  space: "unfi.eth",
  minimumVotingPower: 5,
  govApiBaseUrl,
  maxStake: 637_000,
};
