import { NotificationType } from "../Components/Notifications";
import { Emitter, EmitterAction } from "../Utils/EventEmitter";

export const useNotifications = () => {
  const sendNotification = (payload: {
    notification: NotificationType;
    type: "error" | "warning" | "success" | "info";
  }) => {
    Emitter.emit(EmitterAction.NOTIFICATION, payload);
  };

  return {
    sendNotification,
  };
};
