import React, { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AdapterBalance } from "../../Adapter/IAdapter";
import { Adapter } from "../../Store/Adapter";
import { Balances } from "../../Store/Balance";
import { useToasts } from "react-toast-notifications";
import { Emitter, EmitterAction } from "../../Utils/EventEmitter";
import { Notification, NotificationType } from "../Notifications";
import { useInterval } from "../../Utils/useInterval";
import { useAdapter } from "../../Hooks/useAdapter";
import { BN } from "../../Utils/BigNumber";
import { Blockchains, OfflineConnectors } from "@unifiprotocol/core-sdk";
import { useBalances } from "../../Hooks/useBalances";

type CoinGeckoResponse = {
  [tokenId: string]: {
    usd: number;
  };
};

export const Updater = () => {
  const adapter = useRecoilValue(Adapter);
  const { connect } = useAdapter();
  const { getBalances } = useBalances();
  const [, setBalances] = useRecoilState(Balances);
  const { addToast } = useToasts();

  const updateStateBalances = useCallback(
    (balances: AdapterBalance[]) => {
      balances.forEach((b) => {
        setBalances(
          (state) =>
            new Proxy(
              { ...state, [b.name]: b.balance },
              {
                get: (target, prop: string) => target[prop] ?? "0",
              }
            )
        );
      });
    },
    [setBalances]
  );

  const fetchUnfiPrice = useCallback(async () => {
    try {
      const res: CoinGeckoResponse = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=unifi-protocol-dao&vs_currencies=usd`
      ).then((res) => res.json());
      const resValues = Object.values(res);
      if (resValues.length > 0) {
        const { usd } = resValues[0];
        const result = BN(usd).toFixed();
        Emitter.emit(EmitterAction.BALANCE, [
          { name: "UNFI_USD", balance: result },
        ]);
      }
    } catch (err) {}
  }, []);

  const fetchBalances = useCallback(() => {
    if (!adapter) return;
    getBalances();
  }, [adapter, getBalances]);

  useEffect(() => {
    fetchBalances();
    fetchUnfiPrice();
    Emitter.on(EmitterAction.BALANCE, updateStateBalances as any);
    Emitter.on(EmitterAction.REFRESH_BALANCES, fetchBalances as any);
  }, [adapter, updateStateBalances, fetchBalances, fetchUnfiPrice]);

  useEffect(() => {
    const cb = (payload: {
      notification: NotificationType;
      type: "error" | "warning" | "success";
    }) => {
      addToast(<div>{Notification[payload.notification]}</div>, {
        appearance: payload.type,
      });
    };
    Emitter.on(EmitterAction.NOTIFICATION, cb as any);
    return () => {
      Emitter.off(EmitterAction.NOTIFICATION, cb);
    };
  }, [addToast]);

  useEffect(() => {
    if (adapter?.isConnected()) return;
    const blockchain = localStorage.getItem("blockchain") as
      | Blockchains
      | undefined;
    const connector = localStorage.getItem("connector") as OfflineConnectors;
    if (!blockchain || !connector) return;
    connect(blockchain, connector, false);
    // eslint-disable-next-line
  }, []);

  useInterval(
    () => {
      Emitter.emit(EmitterAction.REFRESH_BALANCES);
    },
    adapter ? 5000 : null
  );

  return <></>;
};
