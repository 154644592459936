import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useAdapter } from "Hooks/useAdapter";
import { useDetectOutsideClick } from "Hooks/useDetectOutsideClick";
import { Close } from "@material-ui/icons";
import { Blockchains, WalletConnectors } from "@unifiprotocol/core-sdk";

import "./ConnectionModal.scss";
import { BlockchainSelector } from "../BlockchainSelector";
import { WalletSelector } from "../WalletSelector";

export const ConnectionModal: React.FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const $modal = useRef<HTMLDivElement>(null);
  const { isActive, setIsActive } = useDetectOutsideClick($modal, isOpen);
  const { connect } = useAdapter();
  const [blockchain, setBlockchain] = useState<Blockchains>();

  const onConnectClick = (
    blockchain: Blockchains,
    wallet: WalletConnectors
  ) => {
    connect(blockchain, wallet);
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setIsActive(isOpen);
    }
  }, [isOpen, isActive, setIsActive]);

  return (
    <Modal isOpen={isActive}>
      <div ref={$modal} className="ConnectionModal">
        <div className="ConnectionModal__header">
          <span>Connect to wallet</span>
          <span
            className="ConnectionModal__header__close"
            onClick={() => {
              setIsActive(false);
              close();
            }}
          >
            <Close />
          </span>
        </div>

        <div className="ConnectionModal__body">
          {blockchain === undefined ? (
            <BlockchainSelector
              onSelect={(b) => setBlockchain(b)}
            ></BlockchainSelector>
          ) : (
            <WalletSelector
              blockchain={blockchain}
              onSelect={(wallet) => onConnectClick(blockchain, wallet)}
            ></WalletSelector>
          )}
        </div>
      </div>
    </Modal>
  );
};
