import React from "react";

import "./UnfiGovernanceHeader.scss";

export const UnfiGovernanceHeader = () => {
  return (
    <div className="UnfiGovernanceHeader">
      <div className="UnfiGovernanceHeader__left">
        <div className="UnfiGovernanceHeader__left__title">Referendums</div>
        <div className="UnfiGovernanceHeader__left__body">
          Community referendums provide a voice in the GGM to all UNIFI holders
          and can be used for any topic. Connect to a wallet with a balance of
          UNIFI tokens, which also includes any staked UNIFI. Vote in the
          existing referendums, or create a new one, and utilize the power of
          UNIFI to influence cross-chain DeFi governance on the Unifi Protocol!
          <p>
            For more information, click{" "}
            <a
              href="https://medium.com/unifiprotocol/community-referendums-the-next-step-on-the-path-towards-ggm-9bdcd0f84b7f"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
        {/* <div className="UnfiGovernanceHeader__left__more-info">
          <a
            href="https://unifiprotocol.zendesk.com/hc/en-us/articles/360051852432-Global-Governance-Model-GGM-"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button onClick={() => {}}>Learn more</Button>
          </a>
        </div> */}
      </div>
      <div className="UnfiGovernanceHeader__right hide-for-mobile"></div>
    </div>
  );
};
