import { Breadcrumbs } from "Components/Breadcrumbs";
import React from "react";

import "./Body.scss";
interface BodyParams {
  className?: string
};

export const Body: React.FC<BodyParams> = ({ className = '', children }) => {
  return <div className={`Body ${className}`}>
    <div className="hide-for-mobile"><Breadcrumbs /></div>
    {children}
  </div>;
};
