import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { CurrentUser } from "Store/User";
import { ContractMethod } from "../Adapter/Contract";
import { NON_SUCCESS_RESPONSE } from "../Adapter/IAdapter";
import { Adapter } from "../Store/Adapter";
import { BN } from "../Utils/BigNumber";
import { useContracts } from "./useContracts";
import { usePrecision } from "./usePrecision";

export const useStakeContract = () => {
  const adapter = useRecoilValue(Adapter);
  const contracts = useContracts();
  const { getPrecision } = usePrecision();
  const stakeContractAddress = contracts?.STAKE_UNFI?.address;
  const user = useRecoilValue(CurrentUser);

  const getTotalUserClaimed = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.TOTAL_CLAIMED,
      { args: [adapter.getAddress()], callValue: undefined },
      false
    );
    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const getPendingClaim = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.PENDING_CLAIM,
      { args: [adapter.getAddress()], callValue: undefined },
      false
    );
    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const getNextClaimableBlock = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.NEXT_CLAIMABLE_BLOCK,
      { args: [adapter.getAddress()], callValue: undefined }
    );

    if (BN(adapterResponse.value).isZero()) return "0";

    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const claim = async () => {
    if (!user || !adapter || !stakeContractAddress) return NON_SUCCESS_RESPONSE;
    const unstakeResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.CLAIM,
      { args: [], callValue: 0 },
      true
    );
    return unstakeResponse;
  };

  return { claim, getPendingClaim, getTotalUserClaimed, getNextClaimableBlock };
};
