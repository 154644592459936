import React, { useCallback, useEffect, useState } from "react";
import { Emitter, EmitterAction } from "Utils/EventEmitter";
import { useNotifications } from "../../Hooks/useNotifications";
import { useStakeContract } from "../../Hooks/useStakeContract";
import { BN, toPresentationLength } from "../../Utils/BigNumber";
import { useInterval } from "../../Utils/useInterval";
import { Button } from "../Button";

import "./ClaimUNFI.scss";

export const ClaimUNFI = () => {
  const { sendNotification } = useNotifications();
  const { claim, getPendingClaim, getTotalUserClaimed } = useStakeContract();
  const [pendingClaim, setPendingClaim] = useState("0");
  const [totalUserClaimed, setTotalUserClaimed] = useState("0");

  const onClaimSubmit = useCallback(() => {
    if (BN(pendingClaim).isLessThanOrEqualTo(0)) {
      Emitter.emit(EmitterAction.NOTIFICATION, {
        notification: "INSUFFICIENT",
        type: "error",
      });
      return;
    }

    claim().then((exe) => {
      sendNotification({
        notification: exe.success ? "CLAIM" : "CLAIM_FAILED",
        type: exe.success ? "success" : "error",
      });
    });
  }, [claim, sendNotification, pendingClaim]);

  useEffect(() => {
    getPendingClaim().then(setPendingClaim);
  }, [getPendingClaim, setPendingClaim]);

  useEffect(() => {
    getTotalUserClaimed().then(setTotalUserClaimed);
  }, [getTotalUserClaimed, setTotalUserClaimed]);

  useInterval(() => {
    getPendingClaim().then(setPendingClaim);
    getTotalUserClaimed().then(setTotalUserClaimed);
  }, 10000);

  return (
    <div className="ClaimUNFI">
      <div className="ClaimUNFI__title">Claimable UNIFI</div>
      <div className="ClaimUNFI__body">
        <div className="ClaimUNFI__body__amount">{toPresentationLength(pendingClaim)}</div>
        <div className="ClaimUNFI__body__amount-title">Unclaimed UNIFI</div>
        <div className="ClaimUNFI__body__amount"></div>
        <div className="ClaimUNFI__body__amount-title">
          Total claimed UNIFI: {toPresentationLength(totalUserClaimed)}
        </div>
      </div>
      <div className="ClaimUNFI__submit">
        <Button onClick={onClaimSubmit}>Claim UNIFI</Button>
      </div>
    </div>
  );
};
