import React from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { Adapter } from "../../Store/Adapter";
import { ConnectedModal } from "../ConnectedModal";
import { ConnectionModal } from "../ConnectionModal";
import { ReactComponent as UnifiLogo } from "Assets/Unifi.svg";
import { ReactComponent as UnifiNoText } from "Assets/Unifi-notext.svg";
import { useHistory } from "react-router-dom";
import { ConnectionModalOpened } from "../../Store/ConnectionModalOpened";
import { ImFileText2 } from "react-icons/im";
import { CurrentUser } from "Store/User";

import "./Header.scss";

export const Header: React.FC = ({ children }) => {
  const history = useHistory();
  const adapter = useRecoilValue(Adapter);
  const user = useRecoilValue(CurrentUser);
  const isConnected = !!user;

  const [connectionModal, setConnectionModal] = useRecoilState(
    ConnectionModalOpened
  );

  return (
    <div className="Header">
      <div className="Header__info">
        <span className="Header__info__logo" onClick={() => history.push("/")}>
          <UnifiLogo />
          <span>
            <span>Governance</span>
          </span>
        </span>

        <span className="Header__info__right-menu">
          <div className="Header__info__right-menu__links">
            <span
              onClick={() =>
                window.open(
                  "https://unifiprotocol.zendesk.com/hc/en-us/articles/360051852432-Global-Governance-Model-GGM-"
                )
              }
            >
              <ImFileText2 /> Resources
            </span>
            <span onClick={() => window.open("https://app.unifiprotocol.com")}>
              <UnifiNoText /> App
            </span>
          </div>

          <span
            className={`Header__info__right-menu__address ${
              isConnected ? "connected" : "no-connected"
            }`}
            onClick={() => setConnectionModal(true)}
          >
            {isConnected ? adapter?.getAddress() : "Connect a Wallet"}
          </span>
        </span>

        {!isConnected && (
          <ConnectionModal
            isOpen={!isConnected && connectionModal}
            close={() => setConnectionModal(false)}
          />
        )}
        {isConnected && (
          <ConnectedModal
            user={user!}
            isOpen={isConnected && connectionModal}
            close={() => setConnectionModal(false)}
          />
        )}
      </div>
      <div className="Header__children">{children}</div>
    </div>
  );
};
