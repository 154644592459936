import { useCallback, useMemo } from "react";
import { Config } from "../Config";
import { useContracts } from "./useContracts";

type PowerDictionary = {
  [k in string]?: { decimals: number; precision: number };
};

export const usePrecision = () => {
  const contracts = useContracts();

  const powerDictionary: PowerDictionary = useMemo(() => {
    const newDictionary: PowerDictionary = {};
    Object.keys(contracts || {}).forEach((c) => {
      const contractName = c as keyof typeof contracts;
      const contract = contracts![contractName];
      newDictionary[contractName] = {
        decimals: contract.decimals,
        precision: Math.pow(10, contract.decimals),
      };
      newDictionary[contract.address] = {
        decimals: contract.decimals,
        precision: Math.pow(10, contract.decimals),
      };
    });
    return newDictionary;
  }, [contracts]);

  const getPrecision = useCallback(
    (tokenOrContract: string): number => {
      return powerDictionary[tokenOrContract]
        ? powerDictionary[tokenOrContract]!.precision
        : Config.globalPrecision;
    },
    [powerDictionary]
  );

  const getDecimals = useCallback(
    (tokenOrContract: string): number => {
      return powerDictionary[tokenOrContract]
        ? powerDictionary[tokenOrContract]!.decimals
        : Config.globalPowerPrecision;
    },
    [powerDictionary]
  );

  return { getPrecision, getDecimals };
};
