import React from "react";
import { Button } from "../../Components/Button";

import "./HomeHeader.scss";

export const HomeHeader = () => {
  return (
    <div className="HomeHeader">
      <div className="HomeHeader__left">
        <div className="HomeHeader__left__title">Unifi Protocol DAO</div>
        <div className="HomeHeader__left__body">
          UNIFI is the first sustainable DeFi token to incorporate Proof of
          Stake elements into its powerful DAO representative governance model.
          Start earning rewards now as we count down to the launch of the
          revolutionary{" "}
          <a
            href="https://medium.com/unifiprotocol/unfi-proof-of-stake-3399130ddda3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Proof of Stake Global Governance Model
          </a>{" "}
          powered by UNIFI!
        </div>
        <div className="HomeHeader__left__more-info">
          <Button
            href="https://unifiprotocol.zendesk.com/hc/en-us/articles/360051852432-Global-Governance-Model-GGM-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Button>
        </div>
      </div>
      <div className="HomeHeader__right hide-for-mobile"></div>
    </div>
  );
};
