import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { CurrentUser } from "Store/User";
import { Emitter, EmitterAction } from "Utils/EventEmitter";
import { ContractMethod } from "../Adapter/Contract";
import { Adapter } from "../Store/Adapter";
import { BN, toHex } from "../Utils/BigNumber";
import { useBalances } from "./useBalances";
import { useContracts } from "./useContracts";
import { useERC20 } from "./useERC20";
import { useGovernance } from "./useGovernance";
import { usePrecision } from "./usePrecision";

const NEW_VOTING_CONTRACT_BLOCK_HEIGHT = 12767165;

export const useLevel3 = () => {
  const adapter = useRecoilValue(Adapter);
  const user = useRecoilValue(CurrentUser);
  const { getUserVotingPower: fetchUserVotingPower } = useGovernance();
  const contracts = useContracts();
  const { getPrecision } = usePrecision();
  const levelThreeAddress = useMemo(
    () => contracts?.LVL3?.address,
    [contracts]
  );
  const { getBalance } = useBalances();
  const { allowance, approve } = useERC20(contracts?.UNFI.address as string);

  const assertWalletIsConnected = useCallback(() => {
    if (!user) {
      Emitter.emit(EmitterAction.NOTIFICATION, {
        notification: "NO_WALLET",
        type: "info",
      });
      throw new Error("You need to connect your wallet");
    }
  }, [user]);

  const getUserStake = useCallback(
    async (blockTag: number | "latest" = "latest") => {
      if (!user || !adapter || !levelThreeAddress) {
        return "0";
      }
      const userStake = await adapter.execute(
        levelThreeAddress,
        ContractMethod.USER_STAKE_AMOUNT,
        { args: [adapter.getAddress()], callValue: 0 }
      );
      return BN(userStake.value)
        .dividedBy(getPrecision(levelThreeAddress))
        .toFixed();
    },
    [adapter, levelThreeAddress, user, getPrecision]
  );

  const getComputedBonusRate = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const computedBonusRate = await adapter.execute(
      levelThreeAddress,
      ContractMethod.COMPUTED_BONUS_RATE,
      { args: [], callValue: 0 }
    );
    return BN(computedBonusRate.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, getPrecision]);

  const getRewardRate = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const rewardRate = await adapter.execute(
      levelThreeAddress,
      ContractMethod.REWARD_RATE,
      { args: [], callValue: 0 }
    );
    return BN(rewardRate.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, getPrecision]);

  const getMaxStaking = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const rewardRate = await adapter.execute(
      levelThreeAddress,
      ContractMethod.MAX_STAKING,
      { args: [], callValue: 0 }
    );
    return BN(rewardRate.value).toFixed();
  }, [adapter, levelThreeAddress]);

  const getPendingRewards = useCallback(async () => {
    if (!user || !adapter || !levelThreeAddress) {
      return "0";
    }
    const pendingUserRewards = await adapter.execute(
      levelThreeAddress,
      ContractMethod.STAKE_PENDING_REWARD,
      { args: [adapter.getAddress()], callValue: 0 }
    );
    return BN(pendingUserRewards.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, user, getPrecision]);

  const getTotalStaked = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const totalStake = await adapter.execute(
      levelThreeAddress,
      ContractMethod.TOTAL_STAKE_AMOUNT,
      { args: [], callValue: 0 }
    );
    return BN(totalStake.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, getPrecision]);

  const getTotalClaimed = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const totalClaimed = await adapter.execute(
      levelThreeAddress,
      ContractMethod.CONTRACT_TOTAL_CLAIMED,
      { args: [], callValue: 0 }
    );
    return BN(totalClaimed.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, getPrecision]);

  const getRewardPerToken = useCallback(async () => {
    if (!adapter || !levelThreeAddress) {
      return "0";
    }
    const rewardPerToken = await adapter.execute(
      levelThreeAddress,
      ContractMethod.REWARD_PER_TOKEN,
      { args: [], callValue: 0 }
    );
    return BN(rewardPerToken.value)
      .dividedBy(getPrecision(levelThreeAddress))
      .toFixed();
  }, [adapter, levelThreeAddress, getPrecision]);

  const stake = useCallback(
    async (amount: string) => {
      assertWalletIsConnected();
      if (!levelThreeAddress) {
        throw new Error("Staking is only available in Ethreum network yet.");
      }
      if (!adapter) {
        throw new Error("No adapter is available, try again later.");
      }
      const normalizedAmount = toHex(
        BN(amount)
          .multipliedBy(getPrecision(contracts!.UNFI.address))
          .decimalPlaces(0)
          .toFixed()
      );

      // HAS ALLOWANCE?
      const userAllowance = await allowance(levelThreeAddress);

      if (BN(normalizedAmount).isGreaterThan(userAllowance)) {
        const approveTransaction = await approve(levelThreeAddress);
        await adapter.waitForTransaction(approveTransaction.hash);
      }

      const stakeResponse = await adapter.execute(
        levelThreeAddress,
        ContractMethod.STAKE,
        { args: [normalizedAmount], callValue: 0 },
        true
      );
      return stakeResponse;
    },
    [
      adapter,
      contracts,
      allowance,
      approve,
      levelThreeAddress,
      assertWalletIsConnected,
      getPrecision,
    ]
  );

  const unstake = useCallback(
    async (amount: string) => {
      assertWalletIsConnected();
      if (!adapter) {
        throw new Error("No adapter is available, try again later.");
      }
      const normalizedAmount = toHex(
        BN(amount)
          .multipliedBy(getPrecision(contracts!.UNFI.address))
          .decimalPlaces(0)
          .toFixed()
      );
      const unstakeResponse = await adapter.execute(
        levelThreeAddress!,
        ContractMethod.UNSTAKE,
        { args: [normalizedAmount], callValue: 0 },
        true
      );
      return unstakeResponse;
    },
    [
      adapter,
      levelThreeAddress,
      assertWalletIsConnected,
      contracts,
      getPrecision,
    ]
  );

  const claim = useCallback(async () => {
    if (!user || !adapter) return;
    const unstakeResponse = await adapter.execute(
      levelThreeAddress!,
      ContractMethod.CLAIM,
      { args: [], callValue: 0 },
      true
    );
    return unstakeResponse;
  }, [adapter, levelThreeAddress, user]);

  const hasAllowance = useCallback(
    async (amount: string) => {
      if (!user || !adapter) return false;
      const currentAllowance = await allowance(levelThreeAddress!);
      const normalizedAmount = BN(amount).multipliedBy(
        getPrecision(contracts!.UNFI.address)
      );
      return BN(currentAllowance).isGreaterThan(normalizedAmount);
    },
    [allowance, levelThreeAddress, user, adapter, contracts, getPrecision]
  );

  const approveAllowance = useCallback(
    async (amount?: string) => {
      if (!user || !adapter) return;
      approve(levelThreeAddress!, amount);
    },
    [approve, levelThreeAddress, user, adapter]
  );

  const getVotingPowerBackendFallback = (userAddress: string) => () =>
    fetchUserVotingPower({
      userAddress,
    })
      .then((votingPower) => BN(votingPower).dp(4).toFixed())
      .catch(() => "0");

  const getUserVotingPower = (blockHeight: number): Promise<string> => {
    if (!adapter) {
      throw new Error("No adapter is available, try again later.");
    }
    let votingAddress = contracts!.VOTING_POWER.address;
    if (BN(blockHeight).isLessThan(NEW_VOTING_CONTRACT_BLOCK_HEIGHT)) {
      votingAddress = contracts!.OLD_VOTING_POWER.address;
    }
    return getBalance(votingAddress, adapter.getAddress())
      .then((userBalance) => {
        return BN(userBalance.balance).dp(4).toFixed();
      })
      .catch(getVotingPowerBackendFallback(adapter.getAddress()));
  };

  return {
    hasAllowance,
    approveAllowance,
    getComputedBonusRate,
    getUserStake,
    getRewardRate,
    getPendingRewards,
    getTotalStaked,
    getTotalClaimed,
    getRewardPerToken,
    stake,
    unstake,
    claim,
    getUserVotingPower,
    getMaxStaking,
  };
};
