import React, { useEffect, useMemo, useRef } from "react";
import Modal from "react-modal";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { Config } from "../../Config";
import { Button } from "../Button";
import { Close } from "@material-ui/icons";
import { Emitter, EmitterAction } from "../../Utils/EventEmitter";
import { Balances } from "../../Store/Balance";
import { useDetectOutsideClick } from "../../Hooks/useDetectOutsideClick";
import { useAdapter } from "Hooks/useAdapter";
import { User } from "Adapter/IAdapter";

import UNFI_LOGO from "Assets/UNFI.png";
import ETH_LOGO from "Assets/Tokens/eth.png";
import "./ConnectedModal.scss";
import { useContracts } from "../../Hooks/useContracts";
import { Adapter } from "../../Store/Adapter";

export const ConnectedModal: React.FC<{
  isOpen: boolean;
  user: User;
  close: () => void;
}> = ({ isOpen, user, close }) => {
  const $modal = useRef<HTMLDivElement>(null);
  const { isActive, setIsActive } = useDetectOutsideClick($modal, isOpen);
  const balance = useRecoilValue(Balances);
  const contracts = useContracts();
  const resetBalances = useResetRecoilState(Balances);
  const adapter = useRecoilValue(Adapter);
  const { logout } = useAdapter();

  const nativeToken = useMemo(
    () => adapter?.blockchainConfig.nativeToken,
    [adapter]
  );

  const explorerLink = useMemo(() => {
    return user
      ? Config.accountExplorer.replace(/{{ADDRESS}}/, user.address)
      : "#";
  }, [user]);

  const onDisconnectClick = () => {
    logout();
    resetBalances();
    Emitter.emit(EmitterAction.NOTIFICATION, {
      notification: "DISCONNECT",
      type: "info",
    });
    setIsActive(false);
  };

  useEffect(() => {
    if (isOpen && !isActive) {
      setIsActive(isOpen);
    }

    if (!isActive && isOpen) {
      close();
    }
  }, [isOpen, isActive, setIsActive, close]);

  const drawBalance = useMemo(
    () => (logo: string, title: string, address: string) => {
      const balanceShortened = (balance[address] || "0").substr(0, 16);
      return (
        <div className="ConnectedModal__body__balances__balance">
          <div className="balance">{balanceShortened}</div>
          <div className="title">
            <span>
              <img alt={title} src={logo} />
            </span>{" "}
            {title}
          </div>
        </div>
      );
    },
    [balance]
  );

  return (
    <Modal isOpen={isActive}>
      <div ref={$modal} className="ConnectedModal">
        <div className="ConnectedModal__header">
          <span>Your wallet</span>
          <span
            className="ConnectionModal__header__close"
            onClick={() => setIsActive(false)}
          >
            <Close />
          </span>
        </div>

        <div className="ConnectedModal__body">
          <div className="ConnectedModal__body__address">
            <a href={explorerLink} target="_blank" rel="noopener noreferrer">
              {user.address}
            </a>
          </div>

          <div className="ConnectedModal__body__balances">
            {drawBalance(
              adapter?.blockchainConfig.logoURI || ETH_LOGO,
              nativeToken?.symbol || "ETH",
              nativeToken?.symbol || "ETH"
            )}
            {drawBalance(UNFI_LOGO, "UNIFI", contracts!.UNFI?.address)}
          </div>
          <div className="ConnectedModal__body__logout">
            <Button onClick={onDisconnectClick}>Disconnect</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
