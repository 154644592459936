import { Config } from "Config";
import { useCallback } from "react";
import { ApiError } from "../Types/Error";
import {
  DetailedVotingPower,
  Referendum,
  ReferendumDetail,
} from "../Types/Referendum";

export const useGovernance = () => {
  const getReferendums = async (): Promise<Referendum[]> => {
    const res = await fetch(
      `${Config.govApiBaseUrl}/v2/governance/referendums`
    );
    return await res.json();
  };

  const getReferendum = useCallback(
    async (proposalId: Referendum["id"]): Promise<ReferendumDetail> => {
      const res = await fetch(
        `${Config.govApiBaseUrl}/v2/governance/referendums/${proposalId}`
      );
      return await res.json();
    },
    []
  );

  const getUserVotingPower = useCallback(
    async (data: { userAddress: string }): Promise<string> => {
      return fetch(`${Config.govApiBaseUrl}/v1/users/voting-power`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => res.votingPower);
    },
    []
  );

  const getUserDetailedVotingPower = useCallback(
    async ({
      userAddress,
      blockHeight,
    }: {
      userAddress: string;
      blockHeight: number;
    }) => {
      const response = await fetch(
        `${Config.govApiBaseUrl}/v2/governance/users/${userAddress}/calc-voting-power`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            blockHeight,
          }),
        }
      );
      if (!response.ok) {
        const { code, message } = await response.json().catch(() => {
          throw new Error(
            `Error retrieving detailed user voting power: HTTP_ERR (${response.status})`
          );
        });
        throw new ApiError(
          `Error retrieving detailed user voting power: ${message} (${code})`,
          code
        );
      }

      return (await response.json()) as DetailedVotingPower;
    },
    []
  );

  return {
    getReferendum,
    getReferendums,
    getUserVotingPower,
    getUserDetailedVotingPower,
  };
};
