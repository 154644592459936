import styled from "styled-components";

export const VoteConfirmationWrapper = styled.div`
  width: 25rem;
`;

export const VoteConfirmationHeader = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;

export const VoteConfirmationFooter = styled.div`
  padding: 1rem 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ToogleComponentText = styled.p`
  text-decoration: underline;
  color: gray;
  font-size: 0.75rem;
  text-indent: 0.1rem;
  &:hover {
    cursor: pointer;
  }
`;

export const VoteConfirmationBody = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > p {
    text-align: center;
    font-weight: 600;
  }
`;

export const VoteConfirmationDetail = styled.div`
  margin: 0.5rem 1.5rem;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin: 0.5rem 0;

    > span:first-of-type {
      color: #8f8f8f;
    }

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        height: 1.2rem;
        width: auto;
      }
    }
  }
`;
