import { Memory } from "@material-ui/icons";
import React, {  } from "react";
import { useHistory } from "react-router-dom";
import { Referendum as ProposalType } from "../../Types/Referendum";
import { formatTime } from "../../Utils/Time";
import { Button } from "../Button";
import { CoreTag, NoContentAvailable } from "./Styles";

import "./Referendums.scss";
import { mutateUnfiToUnifi } from "../../Utils/Governance";

export const Referendums: React.FC<{ proposals: ProposalType[] }> = ({ proposals }) => {
  const history = useHistory();  
  return (
    <div className="Proposals">
      <span className="Proposals__create hide-for-mobile">
        <Button onClick={() => history.push("/governance/referendums/create")}>
          Create New Referendum
        </Button>
      </span>
      {proposals.length === 0 ? (
        <NoContentAvailable>
          <h1>Currently, there is no active proposals, create your own!</h1>
        </NoContentAvailable>
      ) : null}
      {proposals.map((r, i) => {
        return (
          <div
            key={i}
            className="Proposals__row"
            onClick={() => history.push(`/governance/referendums/${r.id}`)}
          >
            <div className="Proposals__row__top">{mutateUnfiToUnifi(r.name)}</div>
            <div className="Proposals__row__bottom">
              <div>
                <span className={`status ${r.isClosed ? "closed" : "open"}`}>
                  <span>{r.isClosed ? "Closed" : "Ongoing"}</span>
                </span>
                {r.isCore && (
                  <CoreTag>
                    <Memory />
                    <span>Core</span>
                  </CoreTag>
                )}
                <span className="hide-for-mobile">#{r.id}</span>
                <span className="date">
                  start {formatTime(new Date(r.startDate).valueOf(), false)} end{" "}
                  {formatTime(new Date(r.endDate).valueOf(), false)}
                </span>
              </div>
              <div>{/* <span>{r.executed && "Executed"}</span> */}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
