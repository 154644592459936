import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Close } from "@material-ui/icons";
import {
  VoteConfirmationBody,
  VoteConfirmationDetail,
  VoteConfirmationFooter,
  VoteConfirmationHeader,
  VoteConfirmationWrapper,
} from "./VoteConfirmationStyles";
import {
  DetailedVotingPower,
  ReferendumDetail,
} from "../../../Types/Referendum";
import { useRecoilValue } from "recoil";
import { Adapter } from "../../../Store/Adapter";
import UNFI from "Assets/UNFI.png";
import { Button } from "../../../Components/Button";
import { useNotifications } from "../../../Hooks/useNotifications";
import { LoadingButton } from "../../../Styles";
import { useGovernance } from "../../../Hooks/useGovernance";
import { DetailedVotingPowerTable } from "./DetailedVotingPowerTable";

export const VoteConfirmation: React.FC<{
  isOpen: boolean;
  selectedOption: string | undefined;
  referendum: ReferendumDetail;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}> = ({ isOpen, selectedOption, referendum, onClose, onConfirm }) => {
  const adapter = useRecoilValue(Adapter);

  const { sendNotification } = useNotifications();
  const [isVoting, setIsVoting] = useState(false);
  const [showDetails] = useState(false);

  const { getUserDetailedVotingPower } = useGovernance();
  const [detailedVotingPower, setDetailedVotingPower] =
    useState<DetailedVotingPower | null>();

  useEffect(() => {
    adapter &&
      getUserDetailedVotingPower({
        userAddress: adapter.getAddress(),
        blockHeight: referendum.blockHeight,
      })
        .catch(() => null)
        .then(setDetailedVotingPower);
  }, [adapter, getUserDetailedVotingPower, referendum.blockHeight]);

  const onSubmit = useCallback(() => {
    if (!adapter || !adapter.isConnected()) {
      return sendNotification({
        notification: "NO_WALLET",
        type: "info",
      });
    }
    setIsVoting(true);
    onConfirm()
      .then(() => {
        setIsVoting(false);
        onClose();
      })
      .catch(() => {
        setIsVoting(false);
      });
  }, [adapter, onClose, onConfirm, sendNotification]);

  return (
    <Modal
      isOpen={isOpen && !!selectedOption}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <VoteConfirmationWrapper>
        <VoteConfirmationHeader>
          <span>Confirm your vote</span>
          <Close style={{ cursor: "pointer" }} onClick={onClose} />
        </VoteConfirmationHeader>
        <VoteConfirmationBody>
          <p>Are you sure you want to vote "{selectedOption}"?</p>
          <VoteConfirmationDetail>
            <div>
              <span>Option</span>
              <span>{selectedOption}</span>
            </div>
            <div>
              <span>Snapshot</span>
              <span>{referendum.blockHeight}</span>
            </div>
            <div>
              <span>Your voting power</span>
              <span>
                {detailedVotingPower?.total ?? "Unknown"}
                <img src={UNFI} alt="UNIFI" title="UNIFI" />
              </span>
            </div>
            {/*<div>
              <ToogleComponentText onClick={() => setShowDetails(!showDetails)}>
                {!showDetails ? "Show details" : "Hide details"}
              </ToogleComponentText>
  </div>*/}
            {showDetails && (
              <DetailedVotingPowerTable
                detailedVotingPower={detailedVotingPower!}
              />
            )}
          </VoteConfirmationDetail>
        </VoteConfirmationBody>
        <VoteConfirmationFooter>
          {isVoting ? (
            <LoadingButton />
          ) : (
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSubmit}>Vote</Button>
            </>
          )}
        </VoteConfirmationFooter>
      </VoteConfirmationWrapper>
    </Modal>
  );
};
