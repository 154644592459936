import React, { useEffect, useMemo, useState } from "react";
import { BN, isNaN, localiseNumber } from "../../Utils/BigNumber";
import { Tooltip } from "@material-ui/core";
import { Config } from "../../Config";
import { Balances } from "../../Store/Balance";
import { useRecoilValue } from "recoil";
import { DollarPrice } from "../../Styles";

import "./UNFIStats.scss";
import { useStats } from "../../Hooks/useStats";
import { StoredStats } from "../../Types/Stats";

export const UNFIStats = () => {
  const { getStats } = useStats();
  const balances = useRecoilValue(Balances);
  const [stats, setStats] = useState<StoredStats>();

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  useEffect(() => {
    getStats().then(setStats);
  }, [getStats, setStats]);

  return (
    <div className="UNFIStats">
      <div className="UNFIStats__title">UNIFI Stats</div>
      <div className="UNFIStats__body">
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            ${localiseNumber(BN(unfiPrice).dp(2).toFixed())}
          </span>
          <span className="UNFIStats__body__row__title">
            <Tooltip
              title={`Current staking capacity: ${Config.maxStake.toLocaleString()} UNFI`}
              placement="bottom"
            >
              <span>Market UNIFI price</span>
            </Tooltip>
          </span>
        </div>
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            {localiseNumber(stats?.total_staked ?? "0")}
          </span>
          {!isNaN(stats?.total_staked ?? "0") && (
            <DollarPrice>
              $
              {localiseNumber(
                BN(unfiPrice)
                  .multipliedBy(stats?.total_staked ?? "0")
                  .dp(2)
                  .toFixed()
              )}
            </DollarPrice>
          )}
          <span className="UNFIStats__body__row__title">
            <Tooltip
              title={`Current staking capacity: ${Config.maxStake.toLocaleString()} UNIFI`}
              placement="bottom"
            >
              <span>Total UNIFI staked</span>
            </Tooltip>
          </span>
        </div>
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            {localiseNumber(stats?.total_claimed ?? "0")}
          </span>
          {!isNaN(stats?.total_claimed ?? "0") && (
            <DollarPrice>
              $
              {localiseNumber(
                BN(unfiPrice)
                  .multipliedBy(stats?.total_claimed ?? "0")
                  .dp(2)
                  .toFixed()
              )}
            </DollarPrice>
          )}
          <span className="UNFIStats__body__row__title">
            Total UNIFI claimed
          </span>
        </div>
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            {stats?.apr ?? "0"}%
          </span>
          <span className="UNFIStats__body__row__title">APR %</span>
        </div>
      </div>
    </div>
  );
};
