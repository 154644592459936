import styled from "styled-components";

export const DetailedVotingPowerTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    display: flex;
    justify-content: center;
    flex: 1 1 calc(100% / 3);
    height: 2rem;
  }
`;

export const RoundedBlockchainLogo = styled.img`
  height: 80%;
  border-radius: 100%;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.75rem;
`;
