import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { CurrentUser } from "Store/User";
import { ContractMethod } from "../Adapter/Contract";
import { NON_SUCCESS_RESPONSE } from "../Adapter/IAdapter";
import { Adapter } from "../Store/Adapter";
import { BN, toHex } from "../Utils/BigNumber";
import { useContracts } from "./useContracts";
import { usePrecision } from "./usePrecision";
import { useERC20 } from "./useERC20";
import { NewContractMethod } from "../Adapter/NewContract";

export const useNewStake = () => {
  const adapter = useRecoilValue(Adapter);
  const contracts = useContracts();
  const { getPrecision } = usePrecision();
  const stakeContractAddress = contracts?.NEW_STAKE_UNFI?.address;
  const user = useRecoilValue(CurrentUser);
  const { allowance, approve } = useERC20(contracts?.UNFI.address as string);

  const balanceOf = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.BALANCE_OF,
      { args: [adapter.getAddress()], callValue: undefined },
      false
    );
    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const totalStaked = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await (
      adapter.blockchainConfig.offlineConnectors[0].adapter?.adapter ?? adapter
    ).execute(
      stakeContractAddress,
      NewContractMethod.TOTAL_STAKED,
      { args: [], callValue: undefined },
      false
    );
    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const rewardRate = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await (
      adapter.blockchainConfig.offlineConnectors[0].adapter?.adapter ?? adapter
    ).execute(
      stakeContractAddress,
      NewContractMethod.REWARD_RATE,
      { args: [], callValue: undefined },
      false
    );
    return BN(adapterResponse.value).toFixed();
  }, [adapter, user, stakeContractAddress]);

  const hasAllowance = useCallback(
    async (amount: string) => {
      if (!user || !adapter) return false;
      const currentAllowance = await allowance(stakeContractAddress!);
      return BN(currentAllowance).isGreaterThanOrEqualTo(amount);
    },
    [user, adapter, allowance, stakeContractAddress]
  );

  const earned = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return "0";
    const adapterResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.EARNED,
      { args: [adapter.getAddress()], callValue: undefined },
      false
    );
    return BN(adapterResponse.value)
      .dividedBy(getPrecision(stakeContractAddress))
      .toFixed();
  }, [adapter, user, stakeContractAddress, getPrecision]);

  const stake = useCallback(
    async (amount: string) => {
      if (!user || !adapter || !stakeContractAddress) {
        return NON_SUCCESS_RESPONSE;
      }
      const normalizedAmount = toHex(
        BN(amount)
          .multipliedBy(getPrecision(contracts!.UNFI.address))
          .decimalPlaces(0)
          .toFixed()
      );
      const stakeResponse = await adapter.execute(
        stakeContractAddress,
        ContractMethod.STAKE,
        { args: [normalizedAmount], callValue: 0 },
        true
      );
      return stakeResponse;
    },
    [adapter, contracts, getPrecision, stakeContractAddress, user]
  );

  const unstake = useCallback(
    async (amount: string) => {
      if (!user || !adapter || !stakeContractAddress) {
        return NON_SUCCESS_RESPONSE;
      }
      const normalizedAmount = toHex(
        BN(amount)
          .multipliedBy(getPrecision(contracts!.UNFI.address))
          .decimalPlaces(0)
          .toFixed()
      );
      const unstakeResponse = await adapter.execute(
        stakeContractAddress,
        ContractMethod.WITHDRAW,
        { args: [normalizedAmount], callValue: 0 },
        true
      );
      return unstakeResponse;
    },
    [adapter, contracts, getPrecision, stakeContractAddress, user]
  );

  const withdraw = useCallback(
    async (amount: string) => {
      if (!user || !adapter || !stakeContractAddress)
        return NON_SUCCESS_RESPONSE;
      const unstakeResponse = await adapter.execute(
        stakeContractAddress,
        ContractMethod.WITHDRAW,
        { args: [toHex(amount)], callValue: 0 },
        true
      );
      return unstakeResponse;
    },
    [adapter, stakeContractAddress, user]
  );

  const getRewards = useCallback(async () => {
    if (!user || !adapter || !stakeContractAddress) return NON_SUCCESS_RESPONSE;
    const unstakeResponse = await adapter.execute(
      stakeContractAddress,
      ContractMethod.GET_REWARD,
      { args: [], callValue: 0 },
      true
    );
    return unstakeResponse;
  }, [adapter, stakeContractAddress, user]);

  const approveAllowance = useCallback(
    async (amount?: string) => {
      if (!user || !adapter) return;
      approve(stakeContractAddress!, amount);
    },
    [approve, stakeContractAddress, user, adapter]
  );

  return {
    balanceOf,
    stake,
    withdraw,
    earned,
    hasAllowance,
    approveAllowance,
    unstake,
    totalStaked,
    rewardRate,
    getRewards,
  };
};
