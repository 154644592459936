import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Emitter, EmitterAction } from "Utils/EventEmitter";
import { useRecoilValue } from "recoil";
import { useNotifications } from "../../Hooks/useNotifications";
import { Balances } from "../../Store/Balance";
import { DollarPrice } from "../../Styles";
import {
  BN,
  localiseNumber,
  isNaN,
  toPresentationLength,
} from "../../Utils/BigNumber";
import { useInterval } from "../../Utils/useInterval";
import { Button } from "../Button";
import { Input } from "../Input";
import { useNewStake } from "../../Hooks/useNewStake";

import "./Unstake.scss";

export const NewUnstake = () => {
  const balances = useRecoilValue(Balances);
  const { sendNotification } = useNotifications();
  const { balanceOf, unstake, earned } = useNewStake();
  const [userStake, setUserStake] = useState("0");
  // const [userEarned, setUserEarned] = useState("0");
  const [unstakeAmount, setUnstakeAmount] = useState("0");

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  const onUnstakeSubmit = useCallback(() => {
    if (
      BN(unstakeAmount).isGreaterThan(0) &&
      BN(unstakeAmount).isLessThanOrEqualTo(userStake)
    ) {
      unstake(unstakeAmount).then((exe) => {
        sendNotification({
          notification: exe.success ? "UNSTAKE" : "UNSTAKE_FAILED",
          type: exe.success ? "success" : "error",
        });
      });
    } else {
      Emitter.emit(EmitterAction.NOTIFICATION, {
        notification: "NEW_INSUFFICIENT",
        type: "error",
      });
    }
  }, [unstake, sendNotification, unstakeAmount, userStake]);

  useEffect(() => {
    balanceOf().then((x) => setUserStake(isNaN(x) ? "0" : x));
    // earned().then((x) => setUserEarned(isNaN(x) ? "0" : x));
  }, [balanceOf, earned]);

  useInterval(() => {
    balanceOf().then((x) => setUserStake(isNaN(x) ? "0" : x));
    // earned().then((x) => setUserEarned(isNaN(x) ? "0" : x));
  }, 5000);

  return (
    <div className="Unstake">
      <div className="Unstake__title">Unstake</div>
      <div className="Unstake__body">
        <div className="Unstake__body__amount">
          {toPresentationLength(userStake)}
        </div>
        {!isNaN(userStake) && !BN(userStake).isZero() && (
          <DollarPrice>
            $
            {localiseNumber(
              BN(unfiPrice).multipliedBy(userStake).dp(2).toFixed()
            )}
          </DollarPrice>
        )}
        <div className="Unstake__body__amount-title">Your staked UNIFI</div>
        {/* <div className="Unstake__body__amount">
          {toPresentationLength(userEarned)}
        </div> */}
        {/* {!isNaN(userEarned) && !BN(userEarned).isZero() && (
          <DollarPrice>
            $
            {localiseNumber(
              BN(unfiPrice).multipliedBy(userEarned).dp(2).toFixed()
            )}
          </DollarPrice>
        )}
        <div className="Unstake__body__amount-title">Your earned UNIFI</div> */}
      </div>
      <div className="Unstake__bottom">
        <div className="Unstake__bottom__available"></div>
        <div className="Unstake__bottom__input">
          <Input
            placeholder="Amount"
            max={BN(userStake).toFixed()}
            onChange={setUnstakeAmount}
          />
        </div>
      </div>
      <div className="Unstake__submit">
        <Button onClick={onUnstakeSubmit}>Unstake UNFI</Button>
      </div>
    </div>
  );
};
