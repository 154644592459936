export enum ContractMethod {
  APPROVE = "approve",
  ALLOWANCE = "allowance",
  USER_STAKE_AMOUNT = "userStakeAmount",
  STAKE_PENDING_REWARD = "stakePendingReward",
  REWARD_RATE = "rewardRate",
  TOTAL_CLAIMED = "totalClaimed",
  CONTRACT_TOTAL_CLAIMED = "contractTotalClaimed",
  STAKE = "stake",
  UNSTAKE = "unstake",
  CLAIM = "claim",
  PENDING_CLAIM = "pendingClaimable",
  NEXT_CLAIMABLE_BLOCK = "nextClaimableBlock",
  TOTAL_STAKE_AMOUNT = "totalStakeAmount",
  REWARD_PER_TOKEN = "rewardPerToken",
  COMPUTED_BONUS_RATE = "computedBonusRate",
  MAX_STAKING = "maxStaking",
  BALANCE_OF = "balanceOf",
  WITHDRAW = "withdraw",
  GET_REWARD = "getReward",
  EARNED = "earned",
}
