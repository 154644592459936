import { Blockchains } from "@unifiprotocol/core-sdk";
import * as yup from "yup";
import { BN } from "../Utils/BigNumber";

export interface GetReferendumVotesResponse {
  votes: {
    [addr: string]: { choice: string; votingPower: string; ipfs: string };
  };
  reducedVotes: { [choice: string]: number };
  reducedVotingPower: { [choice: string]: string };
}
export interface Referendum {
  id: string;
  ipfs: string;
  name: string;
  body: string;
  choices: string[];
  creator: string;
  startDate: number;
  endDate: number;
  createdAt: number;
  isClosed: boolean;
  blockHeight: number;
  isCore: boolean;
  signature: string;
}

export interface CreateReferendumDto {
  name: string;
  body: string;
  choices: string[];
  creator: string;
  startDate: number;
  endDate: number;
}

export interface SignedMessageDto {
  type: "referendum" | "vote";
  signature: string;
  payload: any;
}

export interface VoteReferendumDto {
  referendumId: string;
  voter: string;
  choice: number;
}
export interface ReferendumBlocks {
  blockHeights: Partial<Record<Blockchains, number>>;
}

export type ReferendumDetail = Referendum &
  GetReferendumVotesResponse &
  ReferendumBlocks;

export interface SnapshotPageProposal {
  address: string;
  msg: {
    version: string;
    timestamp: string;
    space: string;
    type: "proposal";
    payload: {
      end: number;
      body: string;
      name: string;
      start: number;
      choices: string[];
      snapshot: number;
      type: "single-choice";
      metadata: {
        plugins: {};
        network: string;
        strategies: {
          name: "erc20-balance-of";
          params: {
            symbol: string;
            address: string;
            decimals: 18;
          };
        }[];
      };
    };
  };
  sig: string;
  authorIpfsHash: string;
  relayerIpfsHash: string;
}

export interface CreateReferendumPayload {
  address: string;
  msg: SnapshotPageProposal["msg"];
  sig: string;
}

export type SnapshotPageProposalList = { [id: string]: SnapshotPageProposal };

export interface SnapshotPageProposalVote {
  address: string;
  msg: {
    version: string;
    timestamp: string;
    space: string;
    type: "vote";
    payload: {
      choice: number;
      proposal: string;
      metadata: {};
    };
  };
  sig: string;
}

export type SnapshotPageProposalVoteList = {
  [address: string]: SnapshotPageProposalVote;
};

export interface Choice {
  index: number;
  choice: string;
}

export interface BlockVotingPower {
  blockchain: Blockchains;
  votingPower: string;
  blockHeight: string;
}

export interface DetailedVotingPower {
  total: string;
  blockchains?: Partial<Record<Blockchains, BlockVotingPower>>;
}

const H24_MILLISECONDS = 1000 * 60 * 60 * 24;
const WEEK_MILLISECONDS = H24_MILLISECONDS * 7;
const MONTH_MILLISECONDS = H24_MILLISECONDS * 31;

export const ReferendumSchema = yup.object().shape({
  title: yup.string().required("PROPOSAL_TITLE_ERROR"),
  body: yup.string().required("PROPOSAL_BODY_ERROR"),
  startDate: yup
    .date()
    .test("is-not-past", "PROPOSAL_STARTDATE_PAST", (value) => {
      return BN(value!.valueOf()).isGreaterThan(Date.now() - 60000);
    })
    .test("is-valid-range", "INVALID_REFERENDUM_DURATION", function (value) {
      const referendumLength = BN(this.parent.endDate - value!.valueOf());
      return (
        referendumLength.isGreaterThanOrEqualTo(WEEK_MILLISECONDS) &&
        referendumLength.isLessThanOrEqualTo(MONTH_MILLISECONDS)
      );
    })
    .test({
      name: "start-before-end",
      message: "PROPOSAL_END_BEFORE_START",
      test: function (value) {
        return BN(value!.valueOf()).isLessThan(Number(this.parent.endDate));
      },
    })
    .required("PROPOSAL_STARTDATE_ERROR"),
  endDate: yup
    .date()
    .test("is-in-the-next-24h", "PROPOSAL_ENDDATE_ERROR", (value) => {
      return BN(value!.valueOf())
        .minus(Date.now())
        .isGreaterThan(H24_MILLISECONDS);
    })
    .required("PROPOSAL_ENDDATE_ERROR"),
  choices: yup
    .array()
    .of(yup.string().required("PROPOSAL_CHOICES_ERROR"))
    .min(2, "PROPOSAL_CHOICES_ERROR")
    .required("PROPOSAL_CHOICES_ERROR"),
});

export enum ReferendumCategoryKey {
  Core = "core",
  All = "all",
}
export interface ReferendumCategory {
  key: ReferendumCategoryKey;
  name: string;
}

export const referendumCategories: ReferendumCategory[] = [
  {
    key: ReferendumCategoryKey.Core,
    name: "Core Referendums",
  },
  {
    key: ReferendumCategoryKey.All,
    name: "Referendums",
  },
];
