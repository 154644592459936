import React, { useEffect } from "react";
import { BreadcrumbStyled } from "./Styles";
import { Link, useLocation } from "react-router-dom";
import { AppRoute, appRoutes } from "../../Router";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { CurrentPageTitle } from "Store/Navigation";


const routeWildcards = /:[a-z0-9A-Z\-_]*/g;

const pathRoutesMatcher = (path: string) => (route: AppRoute) => {
  const imd = route.path.replace(routeWildcards, "(.+)");
  return !!path.match(new RegExp(`^${imd}$`));
};

const getPathTitle = (path: string) => {
  const match = appRoutes.find(pathRoutesMatcher(path));
  return match ? match.title : undefined;
};

const getCrumbMeta = (value: string, index: number, pathnames: string[]) => {
  const last = index === pathnames.length - 1;
  const to = `/${pathnames.slice(0, index + 1).join("/")}`;
  return [to, getPathTitle(to), last] as CrumbMeta;
}

const skipUntitledRoutes = ([to, title, last]: CrumbMeta) => !!title;

type CrumbMeta = [string, string, boolean];

export const Breadcrumbs: React.FC<{}> = () => {
  const location = useLocation();
  const resetCurrentPageTitle = useResetRecoilState(CurrentPageTitle);
  const pageTitle = useRecoilValue(CurrentPageTitle)

  const currentPath = location.pathname;
  const currentRoute = appRoutes.find(pathRoutesMatcher(currentPath))
  


  useEffect(()=>{
    resetCurrentPageTitle();
  }, [location.pathname, resetCurrentPageTitle])


  if(currentRoute && currentRoute.hideBreadcrumbs){
    return null;
  }

  const pathnames = currentPath.split("/").filter((x) => x);
  
  return (
    <BreadcrumbStyled>
        <Link to="/">/</Link>
        {pathnames
          .map(getCrumbMeta)
          .filter(skipUntitledRoutes)
          .map(([to, title, last], key) => {
            let displayText = last && pageTitle ? pageTitle : title;
            const MAX = 40;
            if(displayText.length > MAX){
              displayText = displayText.substr(0, MAX-3) + '...'
            }
            
            return (
              <Link 
                key={key} 
                className={last ? 'active' : ''} 
                to={to}>
                {displayText}
              </Link>
            );
          })
        }
    </BreadcrumbStyled>
  );
};
