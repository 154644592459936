import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { Config } from "../../Config";
import { useContracts } from "../../Hooks/useContracts";
import { useLevel3 } from "../../Hooks/useLevel3";
import { useNotifications } from "../../Hooks/useNotifications";
import { Balances } from "../../Store/Balance";
import { DollarPrice } from "../../Styles";
import {
  BN,
  localiseNumber,
  isNaN,
  toPresentationLength,
} from "../../Utils/BigNumber";
import { useInterval } from "../../Utils/useInterval";
import { WarningAlert } from "../Alert";
import { Button } from "../Button";
import { Input } from "../Input";

import "./Stake.scss";

export const Stake = ({ available }: { available: boolean }) => {
  const balances = useRecoilValue(Balances);
  const { sendNotification } = useNotifications();
  const {
    stake,
    hasAllowance,
    approveAllowance,
    getTotalStaked,
    getMaxStaking,
  } = useLevel3();
  const contracts = useContracts();
  const [stakeAmount, setStakeAmount] = useState("0");
  const [totalStake, setTotalStake] = useState("0");
  const [hasEnoughAllowance, setHasEnoughAllowance] = useState(false);
  const [maxStaking, setMaxStaking] = useState("0");

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  const disabledStaking = useMemo(() => BN(maxStaking).eq(0), [maxStaking]);

  const onStakeSubmit = useCallback(() => {
    if (
      BN(stakeAmount).plus(totalStake).isGreaterThanOrEqualTo(Config.maxStake)
    ) {
      sendNotification({
        notification: "STAKE_OVER_CAP",
        type: "warning",
      });
      return;
    }

    if (BN(stakeAmount).isGreaterThan(0)) {
      stake(stakeAmount).then((exe) => {
        sendNotification({
          notification: exe.success ? "STAKE" : "STAKE_FAILED",
          type: exe.success ? "success" : "error",
        });
      });
    }
  }, [stake, sendNotification, stakeAmount, totalStake]);

  // const onUnapprove = useCallback(() => {
  //   approveAllowance("0");
  // }, [approveAllowance]);

  useEffect(() => {
    hasAllowance(stakeAmount).then((val) => {
      setHasEnoughAllowance(val);
    });
  }, [hasAllowance, stakeAmount]);

  useEffect(() => {
    getTotalStaked().then(setTotalStake);
  }, [getTotalStaked]);

  useEffect(() => {
    getMaxStaking().then(setMaxStaking);
  }, [getMaxStaking]);

  useInterval(
    () => {
      hasAllowance(stakeAmount).then((val) => {
        setHasEnoughAllowance(val);
      });
      getTotalStaked().then((val) => setTotalStake(val));
    },
    BN(stakeAmount).isGreaterThan(0) ? 5000 : null
  );

  return (
    <div className="Stake">
      <div className="Stake__title">Stake</div>
      <div className="Stake__body">
        <div className="Stake__body__amount">
          {toPresentationLength(balances[contracts!.UNFI?.address])}
        </div>
        {!isNaN(balances[contracts!.UNFI?.address]) &&
          !BN(balances[contracts!.UNFI?.address]).isZero() && (
            <DollarPrice>
              $
              {localiseNumber(
                BN(unfiPrice)
                  .multipliedBy(balances[contracts!.UNFI?.address] ?? 0)
                  .dp(2)
                  .toFixed()
              )}
            </DollarPrice>
          )}
        <div className="Stake__body__amount-title">UNIFI available</div>
      </div>
      {available ? (
        <>
          <div className="Stake__bottom">
            <div className="Stake__bottom__available"></div>
            <div className="Stake__bottom__input">
              <Input
                placeholder="Amount"
                max={balances[contracts!.UNFI?.address]}
                onChange={setStakeAmount}
              />
            </div>
          </div>
          <div className="Stake__submit">
            {!hasEnoughAllowance && (
              <Button
                disabled={hasEnoughAllowance || disabledStaking}
                onClick={() => approveAllowance()}
              >
                Authorize Wallet
              </Button>
            )}
            <Button
              disabled={
                !hasEnoughAllowance ||
                disabledStaking ||
                BN(totalStake).isGreaterThanOrEqualTo(Config.maxStake) ||
                BN(totalStake).isZero()
              }
              onClick={onStakeSubmit}
            >
              Stake UNIFI
            </Button>
            {/* <Button onClick={() => window.open("")}>More Information</Button> */}
          </div>
        </>
      ) : (
        <WarningAlert>Not available</WarningAlert>
      )}

      {/* <Button disabled={!hasEnoughAllowance} onClick={onUnapprove}>
        Josh Button
      </Button> */}
    </div>
  );
};
