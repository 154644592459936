import styled from "styled-components";

export const CoreTag = styled.span`
  width: 4.5rem;
  padding: 0 0.5rem;
  height: 2rem;
  background: #6f42c1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: #fff;
    width: 1rem;
    height: auto;
  }

  > span {
    color: #fff;
    padding: 0 0.25rem;
    margin: 0;
    font-weight: 600;
  }
`;

export const NoContentAvailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  width: 100%;
  text-align: center;

  h1 {
    color: #292927;
  }
`;
