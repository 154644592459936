import React from "react";
import { TrendingFlat } from "@material-ui/icons";
import { useRecoilValue } from "recoil";
import { Balances } from "../../Store/Balance";
import { useHistory } from "react-router-dom";
import { BN } from "../../Utils/BigNumber";

import "./MyDashboardHeader.scss";
import { useContracts } from "../../Hooks/useContracts";

export const MyDashboardHeader = () => {
  const history = useHistory();
  const contracts = useContracts();
  const balance = useRecoilValue(Balances);

  return (
    <div className="MyDashboardHeader">
      <div className="MyDashboardHeader__left">
        <div className="MyDashboardHeader__left__back">
          <span onClick={() => history.goBack()}>
            <TrendingFlat />
          </span>
        </div>
        <div className="MyDashboardHeader__left__title">My Dashboard</div>
        <div className="MyDashboardHeader__left__body">
          <div className="MyDashboardHeader__left__body__balance-amount">
            {BN(balance[contracts!.UNFI.address]).decimalPlaces(3).toFixed()}{" "}
            UNIFI
          </div>
          <div className="MyDashboardHeader__left__body__balance-title">
            Available Balance
          </div>
        </div>
      </div>
      <div className="MyDashboardHeader__right hide-for-mobile"></div>
    </div>
  );
};
