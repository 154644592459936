import React, { useEffect, useState } from "react";
import { useStakeContract } from "../../Hooks/useStakeContract";

import "./PendingRewardsUNFI.scss";

export const PendingRewardsUNFI = () => {
  const { getNextClaimableBlock } = useStakeContract();
  const [nextClaimableBlock, setNextClaimableBlock] = useState("0");

  useEffect(() => {
    getNextClaimableBlock().then(setNextClaimableBlock);
  }, [getNextClaimableBlock, setNextClaimableBlock]);

  return (
    <div className="ClaimUNFI">
      <div className="ClaimUNFI__title">Pending Claim</div>
      <div className="ClaimUNFI__body">
        <div className="ClaimUNFI__body__amount">{nextClaimableBlock}</div>
        <div className="ClaimUNFI__body__amount-title">
          Next claimable block height
        </div>
      </div>
      <div className="Claim__submit"></div>
    </div>
  );
};
