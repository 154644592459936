import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Config } from "../Config";
import { Adapter } from "../Store/Adapter";
import { BN } from "../Utils/BigNumber";
import { Emitter, EmitterAction } from "../Utils/EventEmitter";
import { useContracts } from "./useContracts";

export const useBalances = () => {
  const adapter = useRecoilValue(Adapter);

  const contracts = useContracts();

  useEffect(() => {
    if (!adapter || contracts === undefined) return;

    Object.values(contracts).forEach((contract) =>
      adapter.initializeContract(contract.address, contract.abi)
    );
  }, [adapter, contracts]);

  const getBalance = useCallback(
    async (contractAddress: string, address: string) => {
      if (!adapter) return { name: contractAddress, balance: "0" };
      return {
        name: contractAddress,
        balance: await adapter
          .execute(contractAddress, "balanceOf", {
            args: [address],
          })
          .then(({ value }) =>
            BN(value).dividedBy(Config.globalPrecision).toString()
          ),
      };
    },
    [adapter]
  );

  const getBalances = useCallback(
    async (targetAddress = adapter?.getAddress()) => {
      if (!adapter || !contracts) {
        return [];
      }
      try {
        const nativeBalance = (
          await adapter.getProvider().getBalance(targetAddress)
        ).toString();
        const nativeToken = {
          name: adapter.blockchainConfig.nativeToken.symbol,
          balance: BN(nativeBalance)
            .dividedBy(Config.globalPrecision)
            .toString(),
        };
        Emitter.emit(EmitterAction.BALANCE, [nativeToken]);

        const balances = [];
        for (const cntrct of Object.keys(contracts)) {
          if (!/^UNFI/.test(cntrct)) continue;
          const contract = cntrct as keyof typeof contracts;
          const token = await getBalance(
            contracts[contract].address,
            targetAddress
          );
          balances.push(token);
          Emitter.emit(EmitterAction.BALANCE, [token]);
        }

        return [nativeToken, ...balances];
      } catch (err) {
        return [];
      }
    },
    [adapter, getBalance, contracts]
  );
  return { getBalances, getBalance };
};
