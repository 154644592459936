import { blockchainConfigMap, Blockchains } from "@unifiprotocol/core-sdk";
import React from "react";
import "./index.scss";

const stakingBlockchains = [Blockchains.Ethereum];
const governanceBlockchains = [
  Blockchains.Binance,
  Blockchains.Polygon,
  Blockchains.FTM,
  Blockchains.Avalanche,
  Blockchains.Harmony,
];

export const BlockchainSelector = ({
  onSelect,
}: {
  onSelect: (b: Blockchains) => any;
}) => {
  return (
    <ul>
      <div className="staking-wallets-header">
        Staking and Governance wallets
      </div>
      {stakingBlockchains.map((blockchain) => {
        const config = blockchainConfigMap[blockchain];
        return (
          <li key={blockchain} onClick={() => onSelect(blockchain)}>
            <span>{blockchain}</span>
            <span className="wallet-icon">
              <img src={config?.logoURI} alt={blockchain} />
            </span>
          </li>
        );
      })}
      <div className="staking-wallets-header">Governance wallets</div>
      {governanceBlockchains.map((blockchain) => {
        const config = blockchainConfigMap[blockchain];
        return (
          <li key={blockchain} onClick={() => onSelect(blockchain)}>
            <span>{blockchain}</span>
            <span className="wallet-icon">
              <img src={config?.logoURI} alt={blockchain} />
            </span>
          </li>
        );
      })}
    </ul>
  );
};
