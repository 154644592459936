import { useCallback } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { CurrentUser } from "Store/User";
import {
  blockchainConfigMap,
  Blockchains,
  Connectors,
  getBlockchainConfig,
  getBlockchainConnectorByName,
  IConnector,
  InvalidNetworkError,
  WalletNotDetectedError,
} from "@unifiprotocol/core-sdk";
import { Adapter } from "Store/Adapter";
import { Emitter, EmitterAction } from "Utils/EventEmitter";
import { Connector } from "Store/Connector";
import { toHex } from "@unifiprotocol/utils";
import { Config } from "Config";

enum Result {
  ERROR = 0,
  SUCCESS = 1,
}

export const useAdapter = () => {
  const [, setCurrentUser] = useRecoilState(CurrentUser);
  const unsetCurrentUser = useResetRecoilState(CurrentUser);
  const [adapter, setAdapter] = useRecoilState(Adapter);
  const [connector, setConnector] = useRecoilState<IConnector | undefined>(
    Connector
  );

  const networkChangeHandler = useCallback((chainId) => {
    console.log(`Network has changed to ${chainId}`);
    const blockchainCfg = Object.values(blockchainConfigMap).find(
      (cfg) => cfg?.chainId === chainId && cfg?.unfiToken
    );
    if (blockchainCfg?.wallets !== undefined) {
      const priorConnector = localStorage.getItem("connector");
      const hasSameConnector = blockchainCfg.wallets.some(
        (wallet) => priorConnector === wallet.name
      );
      if (hasSameConnector) {
        localStorage.setItem("blockchain", blockchainCfg.blockchain);
      } else {
        localStorage.removeItem("blockchain");
        localStorage.removeItem("connector");
      }
    } else {
      localStorage.removeItem("blockchain");
      localStorage.removeItem("connector");
    }
    window.location.reload();
  }, []);

  const connect = useCallback(
    async (
      blockchain: Blockchains,
      connectorName: Connectors,
      showNotification = true
    ) => {
      const newConnector = getBlockchainConnectorByName(
        blockchain,
        connectorName
      );

      localStorage.setItem("connector", connectorName);
      localStorage.setItem("blockchain", blockchain);

      newConnector.on("NetworkChanged", networkChangeHandler);

      const { adapter: newAdapter } = await newConnector
        .connect()
        .catch(async (e) => {
          if (e instanceof InvalidNetworkError) {
            await forceNetwork(blockchain, connectorName, connect).catch(() => {
              Emitter.emit(EmitterAction.NOTIFICATION, {
                notification: "WRONG_NETWORK",
                type: "error",
              });
            });
          } else if (e instanceof WalletNotDetectedError) {
            Emitter.emit(EmitterAction.NOTIFICATION, {
              notification: "WRONG_WALLET",
              type: "error",
            });
          }
          return { adapter: null };
        });

      if (newAdapter === null) return;

      if (showNotification) {
        Emitter.emit(EmitterAction.NOTIFICATION, {
          notification: "CONNECTED",
          type: "info",
        });
      }

      if (connectorName !== Config.defaultOfflineProvider) {
        setCurrentUser({
          address: newAdapter.getAddress(),
          signer: newAdapter?.getProvider()?.getSigner(),
        });
      }

      newConnector.on("AddressChanged", async () => {
        setCurrentUser({
          address: newAdapter.getAddress(),
          signer: newAdapter?.getProvider()?.getSigner(),
        });
      });

      if (!newConnector || !newAdapter) {
        throw new Error("Neither connector nor adapter can be undefined.");
      }

      setConnector(newConnector);
      setAdapter(newAdapter);
    },
    [setAdapter, setCurrentUser, setConnector, networkChangeHandler]
  );

  const logout = useCallback(async () => {
    if (!connector) {
      throw new Error("Connector not available, try again later.");
    }
    localStorage.removeItem("connector");
    localStorage.removeItem("blockchain");
    await connector?.disconnect().then(unsetCurrentUser);
    setAdapter(undefined);
    connector.off("NetworkChanged", networkChangeHandler);
  }, [connector, unsetCurrentUser, setAdapter, networkChangeHandler]);

  const isSiteAllowed = useCallback(
    async () => await adapter?.isConnected(),
    [adapter]
  );

  return {
    connect,
    logout,
    isSiteAllowed,
    forceNetwork,
  };
};

const forceNetwork = async (
  blockchain: Blockchains,
  connectorName: Connectors,
  onSuccess: Function,
  onError?: Function
): Promise<Result> => {
  if (!window?.ethereum?.request) {
    onError && onError();
    return Result.ERROR;
  }
  const ethereumConfig = blockchainConfigMap[blockchain];
  const chainId = toHex(blockchainConfigMap[blockchain]?.chainId || 1);
  try {
    await window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      })
      .then(() => onSuccess && onSuccess(connectorName));
    return Result.SUCCESS;
  } catch (err) {
    const { nativeToken, publicRpc } = getBlockchainConfig(blockchain);
    const nativeCurrency = {
      name: nativeToken.name,
      symbol: nativeToken.symbol,
      decimals: nativeToken.decimals,
    };
    await window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId,
            chainName: ethereumConfig?.blockchain,
            nativeCurrency,
            rpcUrls: [publicRpc],
          },
        ],
      })
      .catch(() => onError && onError());
    return Result.ERROR;
  }
};
