export function formatTime(timeMs: number, includeTime = true) {
  const dt = new Date(timeMs);
  if (includeTime) {
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()} ${addZero(
      dt.getHours()
    )}:${addZero(dt.getMinutes())}`;
  } else {
    return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  }
}

function addZero(i: number) {
  let res: string | number = i;
  if (i < 10) {
    res = "0" + i;
  }
  return res;
}
