import {
  blockchainConfigMap,
  Blockchains,
  WalletConnectors,
} from "@unifiprotocol/core-sdk";
import metamask from "../Assets/Wallets/Metamask.png";
import binancesmartchain from "../Assets/Wallets/BinanceSmartChain.png";
import mathwallet from "../Assets/Wallets/Mathwallet.png";
import trustwallet from "../Assets/Wallets/TrustWallet.svg";
import harmonyOneWallet from "../Assets/Wallets/HarmonyOneWallet.png";
import { TokenLogoResolvers } from "@unifiprotocol/utils";

export const getWalletIcon = (
  blockchain: Blockchains,
  wallet: WalletConnectors
) => {
  return (
    {
      [WalletConnectors.Metamask]: metamask,
      [WalletConnectors.Binance]: binancesmartchain,
      [WalletConnectors.MathWallet]: mathwallet,
      [WalletConnectors.TrustWallet]: trustwallet,
      [WalletConnectors.MetamaskCompatible]: TokenLogoResolvers[blockchain](
        blockchainConfigMap[blockchain]!.wrappedToken.address
      ),
      [WalletConnectors.HarmonyOneWallet]: harmonyOneWallet,
    } as Record<WalletConnectors, any>
  )[wallet];
};
