import React, { useEffect, useState } from "react";
import { Stake } from "../../Components/Stake";
import { Unstake } from "../../Components/Unstake";
import { Claim } from "../../Components/Claim";
import { useLevel3 } from "../../Hooks/useLevel3";
import { ClaimUNFI } from "../../Components/ClaimUNFI";
import { PendingRewardsUNFI } from "../../Components/PendingRewardsUNFI";
import { UNFIStats } from "../../Components/UNFIStats";
import { useContracts } from "../../Hooks/useContracts";
import { useMemo } from "react";
import { NewStake } from "../../Components/NewStake";
import { WarningAlert } from "../../Components/Alert";

import "./MyDashboard.scss";
import { NewUnstake } from "../../Components/NewUnstake";
import { NewUNFIStats } from "../../Components/NewUNFIStats";
import { NewClaim } from "../../Components/NewClaim";

const TABS: {
  [tabName: string]: {
    title: string;
    element: (meta: { isStakeAvailable?: boolean }) => React.ReactNode;
  };
} = {
  transfers: {
    title: "TRANSFERS AND MIGRATIONS",
    element: () => (
      <div className="StakingWrapper">
        <ClaimUNFI />
        <PendingRewardsUNFI />
      </div>
    ),
  },
  stake: {
    title: "STAKE AND CLAIM REWARDS",
    element: ({ isStakeAvailable = false }: { isStakeAvailable?: boolean }) => (
      <>
        {!isStakeAvailable && (
          <WarningAlert>Staking is only available in Ethereum</WarningAlert>
        )}
        <div className="StakingWrapper">
          <Stake available={isStakeAvailable} />
          <Claim />
          <UNFIStats />
          <Unstake />
        </div>
      </>
    ),
  },
  "new-stake": {
    title: "🎉🆕 STAKE AND CLAIM REWARDS",
    element: ({ isStakeAvailable = false }: { isStakeAvailable?: boolean }) => (
      <>
        {!isStakeAvailable && (
          <WarningAlert>Staking is only available in Ethereum</WarningAlert>
        )}
        <div className="StakingWrapper">
          <NewStake available={isStakeAvailable} />
          <NewClaim />
          <NewUnstake />
          <NewUNFIStats />
        </div>
      </>
    ),
  },
};

export const MyDashboard = () => {
  const { getUserStake } = useLevel3();
  const contracts = useContracts();
  const [activeTab, setActiveTab] = useState<keyof typeof TABS>("new-stake");
  const [, setUserStake] = useState({ level3: "0" });

  useEffect(() => {
    getUserStake().then((v) => setUserStake((us) => ({ ...us, level3: v })));
  }, [getUserStake]);

  const isStakeAvailable = useMemo(
    () => contracts?.hasOwnProperty("STAKE_UNFI"),
    [contracts]
  );

  return (
    <div className="MyDashboard">
      <div className="MyDashboard__content">
        <div className="MyDashboard__content__tabs">
          {Object.keys(TABS).map((t, i) => {
            const tab = t as keyof typeof TABS;
            return (
              <div
                key={i}
                onClick={() => setActiveTab(tab)}
                className={`MyDashboard__content__tabs__tab ${
                  tab === activeTab ? "active" : "no-active"
                }`}
              >
                {TABS[tab].title}
              </div>
            );
          })}
        </div>
        <div className={`MyDashboard__content__body ${activeTab}`}>
          {TABS[activeTab]["element"]({ isStakeAvailable })}
        </div>
      </div>
    </div>
  );
};
