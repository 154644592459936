import { Config } from "../../Config";

export const Notification = {
  NO_WALLET: "Connect a wallet before start.",
  CONNECTED: "Logged in.",
  WRONG_WALLET:
    "The chosen wallet is either not installed or not available. If you have the wallet installed you may need to uninstall other installed wallets.",
  WRONG_NETWORK:
    "The selected wallet is not in the correct network. It must be in Ethereum network.",
  DISCONNECT: "Disconnected.",
  INSUFFICIENT: "There is not enough funds to execute this operation.",
  NEW_INSUFFICIENT:
    "Insufficient funds to complete this transaction. You may only retrieve your staked UNIFI, and any earnings will be included with the withdrawal.",
  AUTHORIZE_WALLET: "Your wallet is being authorized.",
  CLAIM: "Your claim has been performed.",
  CLAIM_SUCCESSFUL: "Your claim has been processed.",
  CLAIM_FAILED: "Your claim has failed.",
  STAKE: "Your stake has been performed.",
  STAKE_OVER_CAP: `The typed amount is over the total staking cap ${Config.maxStake.toLocaleString()} UNIFI).`,
  STAKE_SUCCESSFUL: "Your stake has been processed.",
  STAKE_FAILED: "Your stake has failed.",
  UNSTAKE: "Your unstake has been performed.",
  UNSTAKE_SUCCESSFUL: "Your unstake has been processed.",
  UNSTAKE_FAILED: "Your unstake has failed.",
  INVALID_AMOUNT:
    "Typed amount is invalid - Is not a number or is greater than your balances.",
  PROPOSAL_CREATED: "Your proposal has been created successfully.",
  PROPOSAL_ERROR:
    "There is an error in your proposal - Please, check all the fields.",
  VOTE_SUBMITED: "Your vote has been submitted.",
  VOTE_ERROR: "There is an error processing your vote - Please, try again.",
  NOT_ENOUGH_VOTING_POWER: `You address doesn't have enough voting power for this action - Required ${Config.minimumVotingPower} UNIFI.`,
  LOADING: "Loading.",

  PROPOSAL_TITLE_ERROR: "A proposal title is required.",
  PROPOSAL_BODY_ERROR: "A proposal title is required.",
  PROPOSAL_CHOICES_ERROR: "At least two choices are required.",
  PROPOSAL_STARTDATE_ERROR: "An start date is required for your proposal.",
  USER_REJECT_SIGNATURE: "User has rejected the signature request.",
  INVALID_PAYLOAD:
    "Sent data doesn't meet all the requirements, check it again",
  REFERENDUM_ENDED:
    "The referendum has been closed so votes are no longer accepted.",
  REFERENDUM_NOT_STARTED:
    "The referendum hasn't started so votes aren't accepted yet.",
  PROPOSAL_STARTDATE_PAST: "The referendum start date cannot be passed.",
  PROPOSAL_END_BEFORE_START:
    "The referendum start date cannot be after the end date.",
  INVALID_REFERENDUM_DURATION: "The referendum length is invalid",
  PROPOSAL_ENDDATE_ERROR:
    "An end date is required for your proposal and cannot be earlier than 24h.",
};

export type NotificationType = keyof typeof Notification;
