import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNotifications } from "../../Hooks/useNotifications";
import { Balances } from "../../Store/Balance";
import { DollarPrice } from "../../Styles";
import {
  BN,
  localiseNumber,
  isNaN,
  toPresentationLength,
} from "../../Utils/BigNumber";
import { useInterval } from "../../Utils/useInterval";
import { Button } from "../Button";
import { useNewStake } from "../../Hooks/useNewStake";

import "./Claim.scss";

export const NewClaim = () => {
  const { sendNotification } = useNotifications();
  const { getRewards, earned } = useNewStake();
  const [pendingRewards, setPendingRewards] = useState("0");
  const balances = useRecoilValue(Balances);

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  const onClaimSubmit = useCallback(() => {
    getRewards().then((exe) => {
      if (!exe) return;
      sendNotification({
        notification: exe.success ? "CLAIM" : "CLAIM_FAILED",
        type: exe.success ? "success" : "error",
      });
    });
  }, [getRewards, sendNotification]);

  useEffect(() => {
    earned().then((x) =>
      isNaN(x) ? setPendingRewards("0") : setPendingRewards(x)
    );
  }, [earned, setPendingRewards]);

  useInterval(() => {
    earned().then((x) =>
      isNaN(x) ? setPendingRewards("0") : setPendingRewards(x)
    );
  }, 10000);

  return (
    <div className="Claim">
      <div className="Claim__title">Claim Rewards</div>
      <div className="Claim__body">
        <div className="Claim__body__amount">
          {toPresentationLength(pendingRewards)}
        </div>
        {!isNaN(pendingRewards) && !BN(pendingRewards).isZero() && (
          <DollarPrice>
            $
            {localiseNumber(
              BN(unfiPrice).multipliedBy(pendingRewards).dp(2).toFixed()
            )}
          </DollarPrice>
        )}
        <div className="Claim__body__amount-title">
          Unclaimed staking rewards
        </div>
      </div>
      <div className="Claim__submit">
        <Button onClick={onClaimSubmit}>Claim Rewards</Button>
      </div>
    </div>
  );
};
