import { Config } from "Config";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { Adapter } from "../Store/Adapter";
import { ApiError } from "../Types/Error";
import {
  CreateReferendumDto,
  Referendum,
  ReferendumCategoryKey,
  SignedMessageDto,
  VoteReferendumDto,
} from "../Types/Referendum";
const PROPOSALS_ENDPOINT = "/v2/governance/referendums";
const CORE_PROPOSALS_ENDPOINT = "/v2/governance/core-referendums";

const MESSAGE_ENDPONT = {
  SEND: "/v2/governance/referendums/message",
  VOTE: "/v2/governance/referendums/messages/vote",
  PROPOSAL: "/v2/governance/referendums/messages/proposal",
};
//const MESSAGES_ENDPOINT = "/v2/governance/message";

export const useReferendums = () => {
  const adapter = useRecoilValue(Adapter);

  const getReferendums = useCallback(
    async (
      category: ReferendumCategoryKey = ReferendumCategoryKey.Core
    ): Promise<Referendum[]> => {
      const core = category === ReferendumCategoryKey.Core;

      const res = await fetch(
        `${Config.govApiBaseUrl}${
          core ? CORE_PROPOSALS_ENDPOINT : PROPOSALS_ENDPOINT
        }`,
        {}
      );
      return await res.json();
    },
    []
  );

  const createReferendum = useCallback(
    async (
      payload: Omit<CreateReferendumDto, "signature">
    ): Promise<{
      success: boolean;
      id: string;
      error?: Error;
    }> => {
      if (!adapter || !adapter.isConnected()) {
        return { success: false, id: "" };
      }
      try {
        let response = await fetch(
          `${Config.govApiBaseUrl}${MESSAGE_ENDPONT.PROPOSAL}`,
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const { code, message } = await response.json();
          throw new ApiError(
            `Error retrieving the message to be signed: ${message} (${code})`,
            code
          );
        }

        const message = await response.json();

        const signature = await adapter.signMessage(
          JSON.stringify({
            ...message,
            value: message.message,
          }),
          { type: "typed" }
        );

        const fullPayload: SignedMessageDto = {
          payload: message,
          type: "referendum",
          signature,
        };

        response = await fetch(
          `${Config.govApiBaseUrl}${MESSAGE_ENDPONT.SEND}`,
          {
            method: "POST",
            body: JSON.stringify(fullPayload),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resBody = await response.json();
        if (!response.ok) {
          const { code, message } = resBody;
          throw new ApiError(
            `Error posting new referendum: ${message} (${code})`,
            code
          );
        }

        return { success: true, id: resBody.id };
      } catch (error: any) {
        return { success: false, id: "", error };
      }
    },
    [adapter]
  );

  const voteReferendum = useCallback(
    async (
      payload: Omit<VoteReferendumDto, "signature">
    ): Promise<{
      success: boolean;
      error?: Error;
    }> => {
      if (!adapter || !adapter.isConnected()) {
        return { success: false };
      }
      try {
        let response = await fetch(
          `${Config.govApiBaseUrl}${MESSAGE_ENDPONT.VOTE}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          const { code, message } = await response.json();
          throw new ApiError(
            `Error fetching message to be signed: ${message} (${code})`,
            code
          );
        }

        const message = await response.json();

        const signature = await adapter.signMessage(
          JSON.stringify({
            ...message,
            value: message.message,
          }),
          { type: "typed" }
        );

        const fullPayload: SignedMessageDto = {
          payload: message,
          type: "vote",
          signature,
        };

        response = await fetch(
          `${Config.govApiBaseUrl}${MESSAGE_ENDPONT.SEND}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(fullPayload),
          }
        );

        if (!response.ok) {
          const { code, message } = await response.json();
          throw new ApiError(`Error posting vote: ${message} (${code})`, code);
        }

        return { success: true };
      } catch (error: any) {
        console.error(
          "🚀 ~ file: useReferendum.ts ~ line 101 ~ useReferendum ~ err",
          error
        );
        return { success: false, error };
      }
    },
    [adapter]
  );

  return { createReferendum, getReferendums, voteReferendum };
};
