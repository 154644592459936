import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useContracts } from "../../Hooks/useContracts";
import { useNotifications } from "../../Hooks/useNotifications";
import { Balances } from "../../Store/Balance";
import { DollarPrice } from "../../Styles";
import {
  BN,
  localiseNumber,
  isNaN,
  toPresentationLength,
} from "../../Utils/BigNumber";
import { useInterval } from "../../Utils/useInterval";
import { WarningAlert } from "../Alert";
import { Button } from "../Button";
import { Input } from "../Input";

import "./Stake.scss";
import { useNewStake } from "../../Hooks/useNewStake";

export const NewStake = ({ available }: { available: boolean }) => {
  const balances = useRecoilValue(Balances);
  const { sendNotification } = useNotifications();
  const { stake, hasAllowance, approveAllowance } = useNewStake();
  const contracts = useContracts();
  const [stakeAmount, setStakeAmount] = useState("0");
  const [hasEnoughAllowance, setHasEnoughAllowance] = useState(false);

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  const onStakeSubmit = useCallback(() => {
    if (BN(stakeAmount).isGreaterThan(0)) {
      stake(stakeAmount).then((exe) => {
        sendNotification({
          notification: exe.success ? "STAKE" : "STAKE_FAILED",
          type: exe.success ? "success" : "error",
        });
      });
    }
  }, [stake, sendNotification, stakeAmount]);

  useEffect(() => {
    hasAllowance(stakeAmount).then((val) => {
      setHasEnoughAllowance(val);
    });
  }, [hasAllowance, stakeAmount]);

  useInterval(
    () => {
      hasAllowance(stakeAmount).then((val) => {
        setHasEnoughAllowance(val);
      });
    },
    BN(stakeAmount).isGreaterThan(0) ? 6000 : null
  );

  return (
    <div className="Stake">
      <div className="Stake__title">Stake</div>
      <div className="Stake__body">
        <div className="Stake__body__amount">
          {toPresentationLength(balances[contracts!.UNFI?.address])}
        </div>
        {!isNaN(balances[contracts!.UNFI?.address]) &&
          !BN(balances[contracts!.UNFI?.address]).isZero() && (
            <DollarPrice>
              $
              {localiseNumber(
                BN(unfiPrice)
                  .multipliedBy(balances[contracts!.UNFI?.address] ?? 0)
                  .dp(2)
                  .toFixed()
              )}
            </DollarPrice>
          )}
        <div className="Stake__body__amount-title">UNFI available</div>
      </div>
      {available ? (
        <>
          <div className="Stake__bottom">
            <div className="Stake__bottom__available"></div>
            <div className="Stake__bottom__input">
              <Input
                placeholder="Amount"
                max={balances[contracts!.UNFI?.address]}
                onChange={setStakeAmount}
              />
            </div>
          </div>
          <div className="Stake__submit">
            {!hasEnoughAllowance && (
              <Button
                disabled={hasEnoughAllowance}
                onClick={() => approveAllowance()}
              >
                Authorize Wallet
              </Button>
            )}
            <Button disabled={!hasEnoughAllowance} onClick={onStakeSubmit}>
              Stake UNFI
            </Button>
            {/* <Button onClick={() => window.open("")}>More Information</Button> */}
          </div>
        </>
      ) : (
        <WarningAlert>Not available</WarningAlert>
      )}

      {/* <Button disabled={!hasEnoughAllowance} onClick={onUnapprove}>
        Josh Button
      </Button> */}
    </div>
  );
};
