import { useRecoilValue } from "recoil";
import { ContractMethod } from "../Adapter/Contract";
import { NON_SUCCESS_RESPONSE } from "../Adapter/IAdapter";
import { Adapter } from "../Store/Adapter";
import { BN, toHex } from "../Utils/BigNumber";
import { usePrecision } from "./usePrecision";

const INFINITE_APPROVAL_AMOUNT = toHex(2 ** 255);

export const useERC20 = (contractAddress: string) => {
  const { getPrecision } = usePrecision();
  const adapter = useRecoilValue(Adapter);

  const approve = async (
    targetAddress: string,
    amount: string = INFINITE_APPROVAL_AMOUNT
  ) => {
    if (!adapter?.isConnected()) {
      return NON_SUCCESS_RESPONSE;
    }
    const normalizedAmount = toHex(amount);
    const userTokenApprove = await adapter.execute(
      contractAddress,
      ContractMethod.APPROVE,
      { args: [targetAddress, normalizedAmount], callValue: undefined },
      true
    );
    return userTokenApprove;
  };

  const allowance = async (targetAddress: string) => {
    if (!adapter?.isConnected()) {
      return "0";
    }
    const tokenAllowance = await adapter.execute(
      contractAddress,
      ContractMethod.ALLOWANCE,
      { args: [adapter.getAddress(), targetAddress], callValue: undefined }
    );
    return BN(tokenAllowance.value)
      .dividedBy(getPrecision(contractAddress))
      .toFixed();
  };

  return {
    approve,
    allowance,
  };
};
