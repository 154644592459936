import React from "react";
import styled from "styled-components";

export const InputStyle = styled.div`
  text-align: center;
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  transition: border-color 0.3s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.4);
  }

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

export const LoadingSpinner = styled.div`
  display: inline-block;

  &:after {
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingButtonStyles = styled.div`
  border-radius: 5px;
  background: #8f8f8f;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
`;

export const DollarPrice = styled.span`
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  font-size: 1rem;
`;

export const LoadingButton = () => {
  return (
    <LoadingButtonStyles>
      <LoadingSpinner></LoadingSpinner>
    </LoadingButtonStyles>
  );
};
