export const Theme = {
  primaryColor: "#00e676",
  secondaryColor: "#242732",
  lightGrey: "#242732",
  lightestGrey: "#303340",
  lightGreen: "#38f997f0",
  pink: "#e91e63",
  grey: "#1f2127",
  darkGrey: "#191a21",
  borderRadius: "5px",
  boxShadow: "0 0 15px 0 rgb(0 0 0 / 15%)",
};
