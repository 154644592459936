import React from "react";
import { ImFileText2 } from "react-icons/im";
import { TrendingFlat } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import "./Home.scss";

export const Home = () => {
  const history = useHistory();
  return (
    <div className="Home">
      <div className="Home__items">
        <div
          className="Home__items__item my-dashboard"
          onClick={() => history.push("/my-dashboard")}
        >
          <div className="Home__items__item__icon"></div>
          <div className="Home__items__item__title">My Dashboard</div>
          <div className="Home__items__item__body">
            Keep track of your UNIFI tokens and claim your rewards. Stake and
            delegate your UNIFI to earn rewards. Participate in Community
            Referendums.
          </div>
          <div className="Home__items__item__arrow">
            <TrendingFlat />
          </div>
        </div>
        <div
          className="Home__items__item"
          onClick={() => history.push("/governance")}
        >
          <div className="Home__items__item__icon">
            <ImFileText2 />
          </div>
          <div className="Home__items__item__title">
            Proposals and Referendums
          </div>
          <div className="Home__items__item__body">
            Elected Council Representatives can vote on or propose changes to
            the DAO. Community referendums provide a voice in the GGM on any
            topic to all UNIFI holders.
          </div>
          <div className="Home__items__item__arrow">
            <TrendingFlat />
          </div>
        </div>
      </div>
    </div>
  );
};
