import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BN, isNaN, localiseNumber } from "../../Utils/BigNumber";
import { Tooltip } from "@material-ui/core";
import { Config } from "../../Config";
import { Balances } from "../../Store/Balance";
import { useRecoilValue } from "recoil";
import { DollarPrice } from "../../Styles";
import { useNewStake } from "../../Hooks/useNewStake";
import { useInterval } from "../../Utils/useInterval";
import "./UNFIStats.scss";

const MAGIC_NUMBER = 3_153_600;

export const NewUNFIStats = () => {
  const { rewardRate, totalStaked } = useNewStake();
  const balances = useRecoilValue(Balances);
  const [stats, setStats] = useState<{
    total_staked: string;
    apr: string;
  }>();

  const unfiPrice = useMemo(() => balances["UNFI_USD"] ?? "1", [balances]);

  // (vUnifiStaking.rewardRate * 3153600 ) / vUnifiStaking.totalStaked
  // This will get you a percentage in absolute terms. So like 0.25 for 25%.
  // (vUnifiStaking.rewardRate * 3153600 * 100) / vUnifiStaking.totalStaked
  // Or (vUnifiStaking.rewardRate * 315360000) / vUnifiStaking.totalStaked
  // This will get you the percentage value. So 25 will be 25% (edited)
  const updateStats = useCallback(() => {
    Promise.all([rewardRate(), totalStaked()]).then(
      ([rewardRate, totalStaked]) => {
        const apr =
          BN(rewardRate)
            .multipliedBy(MAGIC_NUMBER)
            .multipliedBy(1000)
            .dividedBy(totalStaked)
            .dividedBy(1e18)
            .dp(3)
            .toFixed() ?? "0";
        setStats({
          total_staked: isNaN(totalStaked) ? "0" : totalStaked,
          apr: isNaN(apr) ? "0" : apr,
        });
      }
    );
  }, [rewardRate, totalStaked]);

  useEffect(() => {
    updateStats();
  }, [updateStats]);

  useInterval(() => {
    updateStats();
  }, 6000);

  return (
    <div className="UNFIStats">
      <div className="UNFIStats__title">UNIFI Stats</div>
      <div className="UNFIStats__body">
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            ${localiseNumber(BN(unfiPrice).dp(2).toFixed())}
          </span>
          <span className="UNFIStats__body__row__title">
            <Tooltip
              title={`Current staking capacity: ${Config.maxStake.toLocaleString()} UNFI`}
              placement="bottom"
            >
              <span>Market UNIFI price</span>
            </Tooltip>
          </span>
        </div>
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            {localiseNumber(stats?.total_staked ?? "0")}
          </span>
          {!isNaN(stats?.total_staked ?? "0") && (
            <DollarPrice>
              $
              {localiseNumber(
                BN(unfiPrice)
                  .multipliedBy(stats?.total_staked ?? "0")
                  .dp(2)
                  .toFixed()
              )}
            </DollarPrice>
          )}
          <span className="UNFIStats__body__row__title">
            <Tooltip
              title={`Current staking capacity: ${Config.maxStake.toLocaleString()} UNFI`}
              placement="bottom"
            >
              <span>Total UNIFI staked</span>
            </Tooltip>
          </span>
        </div>
        <div className="UNFIStats__body__row">
          <span className="UNFIStats__body__row__amount">
            {stats?.apr ?? "0"}%
          </span>
          <span className="UNFIStats__body__row__title">APR %</span>
        </div>
      </div>
    </div>
  );
};
