import styled from "styled-components";

export const BreadcrumbStyled = styled.div`
    border: 1px solid #e8e8e8;
    background: #fff;
    margin-bottom: 1.5rem;

    a {
        position:relative;
        color:#333;
        display: inline-block;
        padding: 0.4rem 0.8rem 0.4rem 1.5rem;
        text-decoration: none;
        transition: background 0.5s;
        &:hover, &:hover:after {
            background:#e8e8e8;
            text-decoration: none;
        }
        &.active, &.active:after {
            background:#f9f9f9;
            text-decoration: none;
        }
    
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -1rem;
            width: 2rem;
            height: 2rem;
            transform: scale(0.707) rotate(45deg);
            z-index: 1;
            border-radius: 0 5px 0 50px;
            background: #fff;
            transition: background 0.5s;
            box-shadow: 1px -1px 0 1px #ededed;

        }
    
    }
`;