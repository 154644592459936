import React from "react";

import "./Button.scss";
type ButtonParams = { 
  onClick?: () => void; 
  disabled?: boolean;
  href?: string;
  target?: '_blank'|'_self'|'_parent'|'_top';
  rel?: string;
}

export const Button: React.FC<ButtonParams> = ({
  onClick,
  disabled,
  children,
  href,
  target,
  rel
}) => {
  if(href){
    return (
      <a className="Button" href={href} target={target} rel={rel} onClick={onClick}>
        {children}
      </a>
    )
  }
  return (
    <button className="Button" disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  href: undefined,
  target: undefined,
  rel: undefined
}