import React, { useMemo } from "react";
import { ReferendumDetail } from "../../../Types/Referendum";
import { BN } from "../../../Utils/BigNumber";
import { ColoredLink, VotesTableRow, VotesTableWrapper } from "../Styles";
import UNFI from "Assets/UNFI.png";
import { Gesture } from "@material-ui/icons";

export const VotesTable: React.FC<{ referendum: ReferendumDetail }> = ({
  referendum,
}) => {
  const voteDicc = useMemo(() => {
    return referendum.choices.reduce(
      (choices: { [opt: string]: string }, choice, idx) => ({
        ...choices,
        [String(idx + 1)]: choice,
      }),
      {}
    );
  }, [referendum]);

  const totalVotes = useMemo(
    () => Object.keys(referendum.votes).length,
    [referendum]
  );

  return (
    <VotesTableWrapper>
      <div className="VoteTableHeader">Votes ({totalVotes})</div>
      <div className="VoteTableBody">
        {Object.keys(referendum.votes).map((voter, idx) => {
          return (
            <VotesTableRow key={idx}>
              <span className="voter-address">{voter}</span>
              <span className="voter-option">
                {voteDicc[referendum.votes[voter].choice]}
              </span>
              <span className="voter-power">
                {BN(referendum.votes[voter].votingPower).dp(4).toFixed()}
                <img src={UNFI} alt="UNIFI" title="UNIFI" />
              </span>
              <span className="voter-power">
                <ColoredLink
                  target="_blank"
                  rel="noreferrer"
                  href={`https://signator.io/view?ipfs=${referendum.votes[voter].ipfs}`}
                >
                  <Gesture></Gesture>
                </ColoredLink>
              </span>
            </VotesTableRow>
          );
        })}
      </div>
    </VotesTableWrapper>
  );
};
