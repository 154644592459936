import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import LOADING_GIF from "../../Assets/loading.gif";

export const LoadingStyled = styled.div`
    img {
        width: 20px;
        vertical-align:middle;
        margin-right: 0.5rem
    }
`;

const loadingMessages: [string, number?][] = [
    ['Loading', 3000],
    ['Swapping time and space...', 8000],
    ['Checking the gravitational constant in your locale...']
];

export const UiLoading = () => {
    const [msgId, setMsgId] = useState(0);
    useEffect(()=>{
        let timer: any;

        const switchMsg = ()=>{
            const time = loadingMessages[msgId][1];
            if(!time){
                return;
            }
            timer = setTimeout(()=>{
                setMsgId((msgId+1) % loadingMessages.length)
            }, time)
        };
        switchMsg();

        return () => clearTimeout(timer);
    })
    return (
        <LoadingStyled>
            <img alt="loading" src={LOADING_GIF} />
            {loadingMessages[msgId][0]}
        </LoadingStyled>
    );
}

export const WrappedLoadingStyled = styled.div`
    background: #fff;
    padding: 1rem;
    border: 1px solid #e8e8e8;
`;

export const UiWrappedLoading = () => (
    <WrappedLoadingStyled>
        <UiLoading/>
    </WrappedLoadingStyled>
)